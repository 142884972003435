import { Delete } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { MobileTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import React, { useState } from 'react'
import { saveOpeningTime } from '../../../services/openingTimeService';
import { days } from './days';
import { useJwt } from '../../../utils/UserProvider';
import showAlert from '../../../utils/ShowAlert';

const OpeningTimeDialog = ({ setLoading, relay, setRelays, setOpTimes, openingTime, setOpeningTime, openAddOpTime, setOpenAddOpTime }
) => {
    const { jwt } = useJwt();

    const [openingTimes, setOpeningTimes] = useState([]);
    const initialState = {
        openingHour: '',
        closingHour: '',
        dayOfWeek: ''
    }
    const [error, setError] = useState(initialState)

    const updateError = (prop, value) => {
        setError(prev => ({
            ...prev,
            [prop]: value
        }));
    }

    const updateOpTimeFields = (prop, value) => {
        setOpeningTime(prevOpTime => ({
            ...prevOpTime,
            [prop]: value
        }));

        if (!value) {
            updateError(prop, 'Ce champ est obligatoire');
        }
        else {
            updateError(prop, '');
        }
    }

    const handleCloseAddOp = () => {
        setOpenAddOpTime(false);
        setOpeningTimes([]);
        if (setRelays)
            setRelays(prev => [relay, ...prev]);
    };

    // Function to handle add opening time
    const handleAddOpeningTime = () => {
        if (error.closingHour === '' && error.openingHour === '' && error.dayOfWeek === '') {
            setOpeningTimes(current => [...current, {
                openingHour: openingTime.openingHour.format('HH:mm'),
                closingHour: openingTime.closingHour.format('HH:mm'),
                dayOfWeek: openingTime.dayOfWeek,
                relay: relay
            }]);
            // Reset fields if necessary
            updateOpTimeFields("openingHour", "");
            updateOpTimeFields("closingHour", "");
            updateOpTimeFields("dayOfWeek", "");
        }
        else {
            showAlert("Veuillez entrer tous les champs !", 'error');
        }
    };

    const saveAllOpeningTimes = async () => {
        setLoading(true);
        if (openingTimes === null || openingTime.openingHour || openingTime.closingHour || openingTime.dayOfWeek) {
            showAlert("Veuillez ajouter les horaires avant de les enregistrer", 'warning');
        }
        else {
            const savedOpTimes = [];
            for (const opTime of openingTimes) {
                const savedOpTime = await saveOpeningTime(opTime, jwt)
                savedOpTimes.push(savedOpTime);
                if (setOpTimes)
                    setOpTimes(prev => [savedOpTime, ...prev])
            }
            // if (!savedOpTimes.includes(null)) {
            //     showAlert("Veuillez ajouter les horaires avant de les enregistrer", 'warning');
            // }
        }
        setLoading(false);
    };
    const deleteOpeningTimeFromList = (opTime) => {
        const array = openingTimes;
        const index = array.indexOf(opTime);
        if (index > -1) { // only splice array when item is found
            array.splice(index, 1); // 2nd parameter means remove one item only
        }
        setOpeningTimes(array);
        updateOpTimeFields("openingHour", "");
        updateOpTimeFields("closingHour", "");
        updateOpTimeFields("dayOfWeek", "");
    };

    return (
        <Dialog
            open={openAddOpTime}
            onClose={handleCloseAddOp}
        >
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    handleAddOpeningTime();
                }}
                onReset={(event) => {
                    event.preventDefault();
                    handleCloseAddOp();
                }}
            >
                <DialogTitle>Ajouter une horaire d'ouverture</DialogTitle>
                <DialogContent>

                    <Grid container spacing={3} sx={{ padding: 3 }}>
                        <Grid item xs={12}>
                            <FormControl fullWidth required>
                                <InputLabel id="day-of-week-label">Sélectionnez le jour</InputLabel>
                                <Select
                                    required
                                    labelId="day-of-week-label"
                                    id="dayOfWeek"
                                    label="Sélectionnez le jour"
                                    value={openingTime.dayOfWeek}
                                    onChange={(e) => updateOpTimeFields("dayOfWeek", e.target.value)}
                                    // error={error.openingHour !== ''}
                                    // helpertext={error.openingHour !== '' && error.openingHour}
                                >
                                    {days.map((day) => [
                                        <MenuItem key={day.value} value={day.value}>
                                            {day.label}
                                        </MenuItem>
                                    ])}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MobileTimePicker
                                slots={{
                                    textField: textFieldProps => <TextField {...textFieldProps} required />
                                }}
                                label="Heure d'ouverture"
                                ampm={false}
                                minTime={dayjs('00:00', 'HH:mm')}
                                maxTime={dayjs('23:59', 'HH:mm')}
                                value={openingTime.openingHour}
                                onChange={(newValue) => {
                                    updateOpTimeFields("openingHour", newValue);
                                    if (openingTime.closingHour && newValue && dayjs(openingTime.closingHour).isBefore(newValue)) {
                                        updateOpTimeFields("closingHour", null);
                                    }
                                }}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MobileTimePicker
                                slots={{
                                    textField: textFieldProps => <TextField {...textFieldProps} required />
                                }}
                                label="Heure de fermeture"
                                ampm={false}
                                minTime={openingTime.openingHour ? dayjs(openingTime.openingHour).add(1, 'minute') : dayjs('00:00', 'HH:mm')}
                                maxTime={dayjs('23:59', 'HH:mm')}
                                value={openingTime.closingHour}
                                onChange={(newValue) => updateOpTimeFields("closingHour", newValue)}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Table sx={{ maxWidth: 650 }} aria-label="List of opening Times to save">
                                <TableHead key='TabHead'>
                                    <TableRow>
                                        <TableCell>Jour</TableCell>
                                        <TableCell align="right">Heure d'ouverture</TableCell>
                                        <TableCell align="right">Heure de fermeture</TableCell>
                                        <TableCell align="right">Retirer</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody key='TabBody'>
                                    {openingTimes.map((opTime) => {
                                        // Find the day object corresponding to opTime.dayOfWeek
                                        const day = days.find(day => day.value === opTime.dayOfWeek);
                                        // Render the label if day is found
                                        const dayLabel = day ? day.label : '';
                                        return (
                                            <TableRow
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row" >
                                                    {dayLabel}
                                                </TableCell>
                                                <TableCell align="right">{opTime.openingHour}</TableCell>
                                                <TableCell align="right">{opTime.closingHour}</TableCell>
                                                <TableCell align="right">
                                                    <IconButton edge="end" aria-label="delete"
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            deleteOpeningTimeFromList(opTime);
                                                        }}
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )

                                    })}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' type="submit">
                        Ajouter
                    </Button>
                    <Button variant='contained'
                        onClick={(event) => {
                            event.preventDefault();
                            saveAllOpeningTimes();
                        }}
                    >
                        Enregistrer
                    </Button>
                    <Typography variant='body2' color='GrayText'> Ou </Typography>
                    <Button variant='outlined' type="reset">
                        Fermer
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default OpeningTimeDialog
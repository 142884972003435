import React, { useEffect, useState } from 'react'
import { Button, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import HomeAdCard from './HomeAdCard';
import { getPublishedAds } from '../../../services/adService';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Preloader from '../../Preloader/Preloader';

const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
        <Button
            className={className}
            onClick={onClick}
            variant="contained"
            size='small'
            sx={{
                bgcolor: 'white',
                '&:hover': {
                    bgcolor: 'white',
                },
                position: 'absolute',
                top: '50%',
                right: '-20px',
                transform: 'translateY(-50%)',
                zIndex: 1,
                pr: 3,
                py: 2,
            }}
        >
            <KeyboardArrowLeftIcon
                sx={{
                    transform: 'rotate(180deg)',
                    color: 'black',
                }}
            />
        </Button>
    );
};

const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
        <Button
            className={className}
            onClick={onClick}
            variant="contained"
            size='small'
            sx={{
                bgcolor: 'white',
                '&:hover': {
                    bgcolor: 'white',
                },
                position: 'absolute',
                top: '50%',
                right: '-20px',
                transform: 'translateY(-50%)',
                zIndex: 1,
                pr: 3,
                py: 2,
            }}
        >
            <KeyboardArrowLeftIcon
                sx={{
                    color: 'black',
                }}
            />
        </Button>
    );
}

const HomeSectionCarousel = ({setLoading}) => {
    const navigate = useNavigate();
    const [ads, setAds] = useState([]);

    useEffect(() => {
        const fetchAds = async () => {
            setLoading(true);
            const adsData = await getPublishedAds();
            if (adsData !== null)
                setAds(adsData);
            setLoading(false);
        };
        fetchAds();
    }, [setLoading]);

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3.5,
                    initialSlide: 3.5,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    initialSlide: 3,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    initialSlide: 3,
                }
            },
            {
                breakpoint: 980,
                settings: {
                    slidesToShow: 2.7,
                    initialSlide: 2.7,
                },
            },
            {
                breakpoint: 920,
                settings: {
                    slidesToShow: 2.5,
                    initialSlide: 2.5,
                },
            },
            {
                breakpoint: 850,
                settings: {
                    slidesToShow: 2.2,
                    initialSlide: 2.2,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 1.5,
                    initialSlide: 1.5
                }
            },
            {
                breakpoint: 520,
                settings: {
                    slidesToShow: 1.2,
                    initialSlide: 1.2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    initialSlide: 1,
                }
            }
        ],
    };

    const items = ads.map(ad =>
        <div key={ad.id}>
            <HomeAdCard ad={ad} />
        </div>
    )


    return (
        <div className='flex flex-col items-center justify-center'>
            <Typography variant="h4" sx={{ mb: 2 }}>
                Annonces
            </Typography>
            {ads.length > 0 ? (
                <div className='w-[75vw] m-auto justify-center align-center'>
                    <div className='mt-5'>
                        <Slider {...settings}>
                            {items}
                        </Slider>
                    </div>
                </div>
            ) : (
                <Preloader />
            )}
            <Button
                variant='outlined'
                onClick={() => navigate('/ads')}
                sx={{ mt: 5 }}
            >
                Voir les annonces
            </Button>
        </div>
    )
}

export default HomeSectionCarousel
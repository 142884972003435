import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import React, { useState } from 'react'
import { saveContent } from '../../../services/contentService'
import { useJwt } from '../../../utils/UserProvider';

const CreateContentDialog = ({ setLoading, openCreateContent, setOpenCreateContent }) => {
    const { jwt } = useJwt();
    const [designation, setDesignation] = useState('');

    const handleSubmitCreateContent = async (e) => {
        e.preventDefault();
        setLoading(true);
        const savedContent = await saveContent({
            designation: designation
        }, jwt);
        if (savedContent !== null) {
            handleCloseCreateContent();
        }
        setLoading(false);
    };

    const handleCloseCreateContent = () => {
        setOpenCreateContent(false);
        setDesignation('');
    };

    return (
        <Dialog
            open={openCreateContent}
            onClose={handleCloseCreateContent}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmitCreateContent,
            }}
        >
            <DialogTitle>Ajouter un type de contenu</DialogTitle>
            <DialogContent sx={{ marginRight: 5 }}>
                <TextField
                    required
                    fullWidth
                    label="Designation"
                    name='designation'
                    value={designation}
                    onChange={(e) => setDesignation(e.target.value)}
                    sx={{ marginY: 1 }}
                />
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={handleCloseCreateContent}>Annuler</Button>
                <Button variant='contained' type="submit">Ajouter</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreateContentDialog
import { Button, FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, Select } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react'

const AdSearchForm = ({ adSearchRequest, updateAdSearch, relays, handleResetSearch }) => {
    return (
        <form
            // onSubmit={(e) => {
            //     e.preventDefault();
            //     handleSubmitSearch();
            // }}
            onReset={(e) => {
                e.preventDefault();
                handleResetSearch();
            }}
        >
            <div className="mb-5">
                <FormControl fullWidth>
                    <FormLabel id="type-label">Type de l'annonce</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="type-label"
                        value={adSearchRequest.type}
                        onChange={(e) => updateAdSearch("type", e.target.value)}
                    >
                        <FormControlLabel
                            key={0}
                            label="Vente"
                            value={'SALE'}
                            control={<Radio />}
                        />
                        <FormControlLabel
                            key={1}
                            label="Demande"
                            value={'REQUEST'}
                            control={<Radio />}
                        />
                    </RadioGroup>
                </FormControl>
            </div>
            <div className="mb-5">
                <FormControl fullWidth size="small">
                    <FormLabel id="departure-relay-label">Départ</FormLabel>
                    {/* <InputLabel id="departure-relay-label">Départ</InputLabel> */}
                    <Select
                        labelId="departure-relay-label"
                        id="departureRelay"
                        displayEmpty
                        variant='standard'
                        value={adSearchRequest.departureRelay ? adSearchRequest.departureRelay : null}
                        onChange={(e) => updateAdSearch("departureRelay", e.target.value)}
                    >
                       {[
                        <MenuItem key={0} value={null} hidden>
                            <span style={{ color: grey[500] }}>Lieu de départ</span>
                        </MenuItem>
                        ]}
                        {relays.map((relay) => [
                            <MenuItem key={relay.id} value={relay}>
                                {relay.city}, {relay.country}
                            </MenuItem>
                        ])}
                    </Select>
                </FormControl>
            </div>
            <div className="mb-5">
                <FormControl fullWidth size="small">
                    <FormLabel id="arrival-relay-label">Arrivée</FormLabel>
                    <Select
                        labelId="arrival-relay-label"
                        id="arrivalRelay"
                        displayEmpty
                        variant='standard'
                        value={adSearchRequest.arrivalRelay ? adSearchRequest.arrivalRelay : null}
                        onChange={(e) => updateAdSearch("arrivalRelay", e.target.value)}
                    >
                        {[
                        <MenuItem value={null} key={0} hidden>
                            <span style={{ color: grey[500] }}>Lieu d'arrivée</span>
                        </MenuItem>
                        ]}
                        {relays.map((relay) => [
                            <MenuItem key={relay.id} value={relay}>
                                {relay.city}, {relay.country}
                            </MenuItem>
                        ])}
                    </Select>
                </FormControl>
            </div>
            <div>
                {/* <Button variant='contained' type='submit'
                    fullWidth sx={{ my: 2, p: 1, fontWeight: 'bold', }}
                >
                    Rechercher
                </Button> */}
                <Button variant='outlined' type='reset'
                    fullWidth sx={{ my: 2, p: 1, fontWeight: 'bold', }}
                >
                    Retirer le filtre
                </Button>
            </div>
        </form>
    )
}

export default AdSearchForm
import { Avatar, Box, Button, Card, CardContent, CardHeader, Chip, CssBaseline, Divider, Stack, Typography } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Footer from '../../public_components/Layouts/Footer';
import { deleteOffer, getOffersByBidder } from '../../../services/offerService';
import { Delete, Luggage, Visibility } from '@mui/icons-material';
import { statusColor, statusLabel, status_array } from '../../../data/offerData';
import { adTypes, typeLabel } from '../../../data/adData';
import SelectFilter from '../SelectFilter/SelectFilter';
import { useNavigate } from 'react-router-dom';
import NavBar from '../../public_components/Layouts/NavBar';
import { MainDrawer, DrawerHeader } from '../../public_components/Layouts/Drawer';
import { useRole } from '../../../utils/UserProvider';
import DeleteDialog from '../../public_components/Layouts/DeleteDialog';
import { useJwt } from '../../../utils/UserProvider';

const BidderOffers = ({setLoading}) => {
  const { jwt } = useJwt();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { role } = useRole();

  const [offers, setOffers] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const offerIdRef = useRef();

  useEffect(() => {
    const fetchOffers = async () => {
      setLoading(true);
      const responseData = await getOffersByBidder(jwt, setOffers);
      if (responseData !== null) {
        setOffers(responseData);
      }
      setLoading(false);
    };

    if (jwt) {
      fetchOffers();
    }
  }, [jwt, setLoading]);


  const handleDeleteOffer = (id) => {
    offerIdRef.current = id;
    setOpenDelete(true);
  }

  const adTypeFilter = {
    label: 'Type',
    value: 'ad',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.value) return null;
      return (params) => {
        const adType = params.row.ad ? params.row.ad.type : null;
        return adType === filterItem.value;
      };
    },
    InputComponent: SelectFilter,
    InputComponentProps: {
      options: adTypes,
    },
  };

  const statusFilter = {
    label: '=',
    value: 'status', // Assuming 'status' is the field in your data
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.value) return null;
      return ({ value }) => value === filterItem.value;
    },
    InputComponent: SelectFilter,
    InputComponentProps: {
      options: status_array,
    },
  };


  const columns = useMemo(() => [
    {
      field: 'actions',
      headerName: "Actions",
      type: 'actions',
      width: 250,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', gap: '5px' }}>
          <Button
            variant="outlined"
            startIcon={<Visibility />}
            color='info'
            size='small'
            onClick={() => {
              navigate(`/ads/${params.row.ad.id}`);
            }}
          >
            Détails
          </Button>
          {params.row.offerStatus !== 'ACCEPTED' &&
            <Button
              variant="outlined"
              startIcon={<Delete />}
              color='error'
              size='small'
              onClick={(e) => {
                e.preventDefault();
                handleDeleteOffer(params.id);
              }}
            >
              Supprimer
            </Button>
          }
        </Box>
      ),
    },
    {
      field: 'offerStatus',
      headerName: "Statut",
      width: 140,
      filterOperators: [statusFilter],
      renderCell: (params) => (
        <Chip
          label={
            statusLabel(params.row.offerStatus)
          }
          variant="outlined"
          color={statusColor(params.row.offerStatus)}
        />
      ),
    },
    {
      field: 'ad',
      headerName: "Annonce Répondue",
      width: 400,
      filterOperators: [adTypeFilter],
      renderCell: (params) => {
        const offer = params.row;
        return (
          <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 4 }}>
            <Stack textAlign='center' sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 0.5 }}>
              <Chip
                avatar={<Avatar src={params.row.ad.picture} />}
                label={
                  typeLabel(params.row.ad.type)
                }
                variant="outlined"
              />
              <Typography variant='body2' component='span'>
                {/* <Place sx={{ height: 20 }} color='primary' /> */}
                {offer.ad.departureRelay && offer.ad.departureRelay.city} à {offer.ad.arrivalRelay && offer.ad.arrivalRelay.city}
              </Typography>
            </Stack>
            <Stack sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography variant='body2' component='span'>
                {params.row.ad.title}
              </Typography>
            </Stack>
          </Stack>
        )
      },
    },
    {
      field: 'details',
      headerName: 'Mon réponse',
      width: 400,
      filterable: false,
      renderCell: (params) => {
        const offer = params.row;
        if (offer.ad.type === 'SALE') {
          return (
            <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 4 }}>
              <Stack sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant='body2' component='span'>
                  {offer.luggageNumber} bagage(s)
                  <Luggage sx={{ height: 20 }} color='primary' />
                </Typography>
                <Typography variant='body2' component='span'>
                  {offer.luggageDescription}
                </Typography>
              </Stack>
              <Stack sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant='body2' component='span'>
                  Contenu des bagages
                </Typography>
                {offer.offerContents && offer.offerContents.map((content) =>
                  <Typography key={content.id} variant='body2' component='span'>
                    {content.designation}
                  </Typography>
                )}
              </Stack>
            </Stack>
          );
        } else if (offer.ad.type === 'REQUEST') {
          return (
            <Stack sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography variant='body2' component='span'>
                {/* <CalendarToday sx={{ height: 20 }} color='primary' /> */}
                Départ le {offer.departureDate}
              </Typography>
              <Typography variant='body2' component='span'>
                et arrivée le {offer.arrivalDate}
              </Typography>
              <Typography variant='body2' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                {/* <Event sx={{ height: 20 }} color='primary' /> */}
                Délai de dépot
                <span>{offer.depositDeadline}</span>
              </Typography>
            </Stack>
          );
        }
      },
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], []);

  const getRowSpacing = useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 0 : 5,
    };
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <NavBar setLoading={setLoading} open={open} setOpen={setOpen} />
      <MainDrawer setLoading={setLoading} open={open} setOpen={setOpen} />
      <Box sx={{ flexGrow: 1, width: '100%' }}>
        <Box component="main" sx={{ p: 3, mt: 3 }}>
          <DrawerHeader />
          <Card
            sx={{
              borderRadius: 3,
              px: 2,
              py: 2,
              marginBottom: 2,
              minHeight: '400px',
            }}
          >
            <CardHeader title="Mes réponses"
              sx={{
                marginBottom: 2,
              }}
            />
            <Divider />
            <CardContent>
              <DataGrid
                columns={columns}
                autoHeight
                rows={offers}
                initialState={{
                  ...offers.initialState,
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterExcludeHiddenColumns: true,
                    },
                  },
                }}
                pageSizeOptions={[5, 10, 25]}
                disableColumnSelector
                getRowHeight={() => 'auto'}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true },
                    csvOptions: { disableToolbarButton: true },
                  },
                }}
                getRowSpacing={getRowSpacing}
                rowSpacingType="border"
                sx={{
                  border: 'none',
                  width: '100%',
                  mb: 5,
                  '& .MuiDataGrid-row': { borderTopColor: 'white', borderTopStyle: 'solid' }
                }}
              />
            </CardContent>
          </Card >
        </Box>
        {role !== 'ADMIN' && role !== 'COORDINATOR' && <Footer />}
      </Box>
      {offerIdRef.current &&
        <DeleteDialog setLoading={setLoading} open={openDelete} setOpen={setOpenDelete}
          deleteMethod={deleteOffer} id={offerIdRef.current} setData={setOffers}
        />
      }
    </Box>
  );
}

export default BidderOffers
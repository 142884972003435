import React, { createContext, useContext, useState, useEffect } from "react";
import { getAuthenticatedUser } from "../services/userService";
import { useLocalState } from "./useLocalStorage";

const RoleContext = createContext();
const JwtContext = createContext();

export const JwtProvider = ({ children }) => {
  const [jwt, setJwt] = useLocalState("jwt", "");
  return <JwtContext.Provider value={{ jwt }}>{children}</JwtContext.Provider>;
};

export const useJwt = () => useContext(JwtContext);

export const RoleProvider = ({ children }) => {
  const [jwt, setJwt] = useLocalState("jwt", "");
  const [role, setRole] = useState(undefined);

  useEffect(() => {
    const fetchAndSetUserRole = async () => {
      try {
        const user = await getAuthenticatedUser(jwt);
        if (user !== null) setRole(user.role);
      } catch (error) {
        console.error("Error fetching user role:", error);
      }
    };

    if (jwt) {
      fetchAndSetUserRole();
    }
  }, [jwt]);

  return (
    <RoleContext.Provider value={{ role }}>{children}</RoleContext.Provider>
  );
};

export const useRole = () => useContext(RoleContext);

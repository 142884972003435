export function capitalizeEachWord(string) {
  if (string && string.trim() !== "") {
    const str_arr = string.split(" ").filter((word) => word.length > 0);

    for (var i = 0; i < str_arr.length; i++) {
      if (str_arr[i]) {
        str_arr[i] = str_arr[i][0].toUpperCase() + str_arr[i].slice(1);
      }
    }
    return str_arr.join(" ");
  }
  return "";
}

export function reassambleName(firstName, lastName) {
  return firstName + " " + lastName;
}

export function getFirstLetterUpperCase(string) {
  if (string && string.trim() !== "") {
    return string.charAt(0).toUpperCase();
  }
  return "";
}

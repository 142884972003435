import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { createOffer } from '../../../services/offerService'
import dayjs from 'dayjs'
import { getContents } from '../../../services/contentService'
import { Cancel, CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import CreateContentDialog from '../ContentCrud/CreateContentDialog'
import { MobileDatePicker, MobileDateTimePicker } from '@mui/x-date-pickers'
import { useJwt } from '../../../utils/UserProvider';
import showAlert from '../../../utils/ShowAlert'

const OfferCreateDialog = ({ setLoading, ad, openCreateOffer, setOpenCreateOffer, setAd }) => {
    const { jwt } = useJwt();

    const [contentTypes, setContentTypes] = useState([]);
    const [openCreateContent, setOpenCreateContent] = useState(false);
    const initialState = {
        price: 1400,
        ad: ad,
        luggageNumber: 0,
        luggageDescription: "",
        offerContents: [],
        depositDeadline: dayjs('', "YYYY-MM-DD HH:mm"),
        departureDate: '',
        arrivalDate: '',
        stopoversDetails: '',
    };
    const [offer, setOffer] = useState(initialState);
    const [error, setError] = useState({
        price: '',
        ad: '',
        luggageNumber: '',
        luggageDescription: '',
        offerContents: '',
        depositDeadline: '',
        departureDate: '',
        arrivalDate: '',
        stopoversDetails: '',
    });

    const updateError = (prop, value) => {
        setError(prev => ({
            ...error,
            [prop]: value
        }));
    }

    const updateOfferFields = (prop, value) => {
        setOffer(prev => ({
            ...prev,
            [prop]: value
        }));
        if (!value) {
            updateError(prop, "Ce champ est obligatoire")
        }
        else {
            updateError(prop, '');
        }
    }

    useEffect(() => {
        const fetchContents = async () => {
            setLoading(true);
            const contentsData = await getContents();
            if (contentsData !== null)
                setContentTypes(contentsData);
            setLoading(false);
        };

        fetchContents();
    }, [openCreateContent, setLoading]);

    const handleOpenCreateContent = () => {
        setOpenCreateContent(true);
    };

    const handleCloseCreateOffer = () => {
        setOpenCreateOffer(false);
        setOffer(initialState);
    };

    const handleSubmitCreateOffer = async (e) => {
        setLoading(true);
        e.preventDefault();
        const formattedOffer = {
            ...offer,
            offerContentIds: offer.offerContents.map(content => content.id),
            ad: {
                ...ad,
                type_name: ad.type
            }
        };
        const responseData = await createOffer(formattedOffer, jwt);
        if (responseData !== null) {
            setAd(prev => ({
                ...prev,
                offersNumber: prev.offersNumber + 1,
            }));
            handleCloseCreateOffer();
            showAlert("Votre réponse a été envoyée !", 'success');
        }
        setLoading(false);
    };

    return (
        <>
            <Dialog
                open={openCreateOffer}
                onClose={handleCloseCreateOffer}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmitCreateOffer,
                }}
            >
                <DialogTitle>Répondre à l'annonce</DialogTitle>
                <DialogContent sx={{ marginRight: 5 }}>
                    {ad && ad.type === 'SALE' ? (
                        <>
                            <TextField
                                required
                                fullWidth
                                label="Nombre de bagages"
                                name='luggageNumber'
                                type='number'
                                inputProps={{ min: 0 }}
                                value={offer.luggageNumber}
                                onChange={(e) => updateOfferFields("luggageNumber", e.target.value)}
                                error={error.luggageNumber !== ''}
                                helperText={error.luggageNumber !== '' ? error.luggageNumber : undefined}
                                sx={{ marginY: 1.5 }}
                            />
                            <TextField
                                required
                                fullWidth
                                label="Description des bagages"
                                name='luggageDescription'
                                value={offer.luggageDescription}
                                onChange={(e) => updateOfferFields("luggageDescription", e.target.value)}
                                error={error.luggageDescription !== ''}
                                helperText={error.luggageDescription !== '' ? error.luggageDescription : undefined}
                                sx={{ marginY: 1.5 }}
                            />
                            <Button variant='outlined'
                                onClick={handleOpenCreateContent}
                                sx={{ marginY: 1.5 }}
                            >
                                Ajouter un type de contenu
                            </Button>

                            <FormControl fullWidth sx={{ marginY: 1.5 }}>
                                <InputLabel>Contenu des bagages</InputLabel>
                                <Select
                                    multiple
                                    value={offer.offerContents}
                                    onChange={(e) => { updateOfferFields("offerContents", e.target.value) }}
                                    error={error.offerContents !== ''}
                                    helpertext={error.offerContents !== '' ? error.offerContents : undefined}
                                    input={<OutlinedInput label="Contenu des bagages" />}
                                    renderValue={(selected) => (
                                        <Stack gap={1} direction="row" flexWrap="wrap">
                                            {selected.map((value) => (
                                                <Chip
                                                    key={value.id}
                                                    label={value.designation}
                                                    onDelete={() =>
                                                        updateOfferFields("offerContents", offer.offerContents.filter((item) => item.id !== value.id))
                                                    }
                                                    deleteIcon={
                                                        <Cancel
                                                            onMouseDown={(event) => event.stopPropagation()}
                                                        />
                                                    }
                                                />
                                            ))}
                                        </Stack>
                                    )}
                                >
                                    {contentTypes.map((content) => [
                                        <MenuItem
                                            key={content.id}
                                            value={content}
                                            sx={{ justifyContent: "space-between" }}
                                        >
                                            {content.designation}
                                            {offer.offerContents.includes(content) ? <CheckBox color="info" /> : <CheckBoxOutlineBlank color="info" />}
                                        </MenuItem>
                                    ])}
                                </Select>
                            </FormControl>
                        </>
                    ) : (
                        <>
                            <MobileDatePicker
                                required
                                id="departureDate"
                                name="departureDate"
                                label="Date de départ *"
                                format='YYYY-MM-DD'
                                disablePast
                                maxDate={offer.arrivalDate}
                                value={offer.departureDate}
                                onChange={(newValue) => { updateOfferFields("departureDate", newValue); }}
                                error={error.departureDate !== ''}
                                helpertext={error.departureDate !== '' && error.departureDate}
                                sx={{ width: '100%', marginY: 1.5 }}
                            />
                            <MobileDatePicker
                                required
                                id="arrivalDate"
                                name="arrivalDate"
                                format='YYYY-MM-DD'
                                label="Date d'arrivée *"
                                minDate={offer.departureDate}
                                value={offer.arrivalDate}
                                onChange={(newValue) => { updateOfferFields("arrivalDate", newValue); }}
                                error={error.arrivalDate !== ''}
                                helpertext={error.arrivalDate !== '' && error.arrivalDate}
                                sx={{ width: '100%', marginY: 1.5 }}
                            />
                            <MobileDateTimePicker
                                required
                                id="depositDeadline"
                                name="depositDeadline"
                                label="Délai de dépôt au lieu de dépôt *"
                                format='YYYY-MM-DD HH:mm'
                                ampm={false}
                                minTime={dayjs('00:00', 'HH:mm')}
                                maxTime={dayjs('23:59', 'HH:mm')}
                                minDate={dayjs()}
                                maxDate={offer.departureDate}
                                value={offer.depositDeadline}
                                onChange={(newValue) => { updateOfferFields("depositDeadline", dayjs(newValue).format("YYYY-MM-DD HH:mm")); }}
                                error={error.depositDeadline !== ''}
                                helpertext={error.depositDeadline !== '' && error.depositDeadline}
                                sx={{ width: '100%', marginY: 1.5 }}
                            />
                            <TextField
                                fullWidth
                                id="stopovers"
                                name="stopovers"
                                label="Escales"
                                rows={3}
                                helperText="Nombre et lieu de(s) stop(s) (Ne sera affiché qu'au coordinateur)"
                                defaultValue={offer.stopovers}
                                onChange={(e) => updateOfferFields("stopovers", e.target.value)}
                            />
                        </>
                    )}

                    <DialogContentText sx={{ fontSize: '12px', my: 3 }}>
                        Après confirmation, vous serez contacter afin de poursuivre le processus.
                        Vous pouvez consulter l'onglet "Mes réponses" pour suivre cette demande.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={handleCloseCreateOffer}>Annuler</Button>
                    <Button variant='contained' type="submit">Envoyer</Button>
                </DialogActions>
            </Dialog>

            <CreateContentDialog setLoading={setLoading} openCreateContent={openCreateContent} setOpenCreateContent={setOpenCreateContent} />
        </>
    )
}

export default OfferCreateDialog
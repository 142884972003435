import ajax from "./ajax";

export const createOffer = async (request, jwt) => {
  return ajax(`/user/offers/create`, "POST", jwt, request);
};

export const getOffers = async (jwt) => {
  return ajax(`/coordinator/offers`, "GET", jwt);
};

export const getOffersByBidder = async (jwt) => {
  return ajax(`/user/offers`, "GET", jwt);
};

export const searchOffers = async (request, jwt) => {
  return ajax(`/user/offers`, "POST", jwt, request);
};

export const getOfferById = async (offerId, jwt) => {
  return ajax(`/user/offers/${offerId}`, "GET", jwt);
};

export const getBiddersByAd = async (adId, jwt) => {
  return ajax(`/coordinator/bidders/ad/${adId}`, "GET", jwt);
};

export const getOffersByAd = async (adId, jwt) => {
  return ajax(`/user/offers/ad/${adId}`, "GET", jwt);
};

export const updateOffer = async (request, jwt) => {
  return ajax(`/user/offers/${request.id}`, "PUT", jwt, request);
};

export const updateOfferStatus = async (request, jwt) => {
  return ajax(`/user/offers/status/${request.id}`, "PUT", jwt, request);
};

export const deleteOffer = async (offerId, jwt) => {
  return ajax(`/user/offers/${offerId}`, "DELETE", jwt);
};

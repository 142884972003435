import ajax from "./ajax";

const imageSale =
  "https://images.pexels.com/photos/842959/pexels-photo-842959.jpeg?auto=compress&cs=tinysrgb&w=600";
const imageRequest =
  "https://images.pexels.com/photos/8984273/pexels-photo-8984273.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2";

export const saveAd = async (ad, jwt) => {
  if (ad.type_name === "SALE") {
    ad.picture = imageSale;
    if (ad.title === "") {
      ad.title = "Kilos en vente";
    }
  } else if (ad.type_name === "REQUEST") {
    ad.picture = imageRequest;
    if (ad.title === "") {
      ad.title = "J'achète des kilos";
    }
  }
  return ajax("/user/ads/create", "POST", jwt, ad);
};

export const getPublishedAds = async () => {
  return ajax("/api/ads", "GET");
};

export const getAds = async (jwt) => {
  return ajax("/coordinator/ads", "GET", jwt);
};

export const searchAds = async (ad) => {
  const requestBody = {
    type_name: ad.type,
    departureRelay: ad.departureRelay,
    arrivalRelay: ad.arrivalRelay,
  };
  return ajax("/api/ads", "POST", null, requestBody);
};

export const getAdById = async (adId, jwt) => {
  return ajax(`/user/ads/${adId}`, "GET", jwt);
};

export const getAdsByPublisher = async (jwt) => {
  return ajax("/user/ads/published", "GET", jwt);
};

export const updateAdProperties = async (id, request, jwt) => {
  const requestBody = {
    status_name: request.status,
    customer: request.customer,
  };
  return ajax(`/user/props/ads/${id}`, "PUT", jwt, requestBody);
};

export const updateAd = async (request, jwt) => {
  return ajax(`/user/ads/${request.id}`, "PUT", jwt, request);
};

export const deleteAd = async (adId, jwt) => {
  return ajax(`/user/ads/${adId}`, "DELETE", jwt);
};

import React, { useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';

const PasswordInput = ({ name, password, update, error, label, handleChange }) => {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    return (
        <TextField
            required
            fullWidth
            // margin="normal"
            label={label ? label : "Mot de passe"}
            id={name}
            name={name}
            onChange={handleChange ? handleChange : (e) => update(name, e.target.value)}
            error={error.password !== ''}
            helperText={error.password}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                endAdornment: (
                    password &&
                    <InputAdornment position="end" sx={{ mr: 2 }}>
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    )
}

export default PasswordInput
import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { updateUser } from '../../../services/userService';
import { capitalizeEachWord } from '../../../utils/stringFunction';
import PhoneNumberField from '../../public_components/Layouts/PhoneNumberField';
import { useJwt } from '../../../utils/UserProvider';

const UserProfile = ({ setLoading, user, setUser, updateUserFields, editable, setEditable, handleReset }) => {
  const { jwt } = useJwt();

  const firstNameData = user.firstName;
  const lastNameData = user.lastName;

  const handleSubmit = async () => {
    console.log(user.phone.substring(5).length);
    setLoading(true);
    const savedUser = await updateUser(user, jwt);
    if (savedUser !== null) {
      setUser({ ...savedUser });
      setEditable(false);
    }
    setLoading(false);
  };

  return (

    <Card
      sx={{
        borderRadius: 3,
        px: 6,
        py: 2,
        marginLeft: 2,
        marginBottom: 2,
        minHeight: '400px',
      }}
      component='form'
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      onReset={(e) => {
        e.preventDefault();
        handleReset();
      }}
    >
      <CardHeader title="Profil" />
      <Divider />
      <CardContent>
        {!editable ?
          (
            <Stack spacing={2}>
              <Stack
                spacing={{ xs: 1, sm: 2 }}
                direction="column" flexWrap="wrap"
              >
                <div>
                  <Typography color="text.secondary" variant='body2' >
                    Nom d'utilisateur
                  </Typography>
                  <Typography variant="body1">
                    {user.username}
                  </Typography>
                </div>
                <div>
                  <Typography color="text.secondary" variant='body2' >
                    Nom
                  </Typography>
                  <Typography variant='body1'>
                    {lastNameData !== "" && capitalizeEachWord(lastNameData)}
                  </Typography>
                </div>
                <div>
                  <Typography color="text.secondary" variant='body2' >
                    Prénom
                  </Typography>
                  <Typography variant="body1">
                    {firstNameData !== "" && capitalizeEachWord(firstNameData)}
                  </Typography>
                </div>
                <div>
                  <Typography color="text.secondary" variant='body2' >
                    Adresse email
                  </Typography>
                  <Typography variant="body1">
                    {user.email}
                  </Typography>
                </div>
                <div>
                  <Typography color="text.secondary" variant='body2' >
                    Téléphone
                  </Typography>
                  <Typography variant="body1">
                    {user.phone}
                  </Typography>
                </div>
              </Stack>
            </Stack>
          )
          :
          (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="firstName"
                  name="firstName"
                  label="Prénom"
                  defaultValue={user.firstName}
                  onChange={(e) => updateUserFields("firstName", e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  name="lastName"
                  label="Nom"
                  defaultValue={user.lastName}
                  onChange={(e) => updateUserFields("lastName", e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <PhoneNumberField
                  phone={user.phone}
                  update={updateUserFields}
                />
              </Grid>
            </Grid>
          )
        }
      </CardContent>
      {
        editable && (
          <>
            <Divider sx={{ mt: 2 }} />
            <CardActions sx={{ justifyContent: 'flex-end' }}>
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 2 }}
              >
                Enregistrer
              </Button>
              <Button
                type="reset"
                variant="outlined"
                sx={{ ml: 4, mt: 2 }}
              >
                Annuler
              </Button>
            </CardActions>
          </>
        )
      }
    </Card >
  )
}

export default UserProfile
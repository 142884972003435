import {
    List, ListItemButton, ListItemText, Typography,
    Drawer as DrawerMui, IconButton, Divider, Tab, Tabs, Box, Tooltip
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
    Menu as MenuIcon,
    DashboardOutlined,
    VolumeDownOutlined,
    LocalOfferOutlined,
    Storefront,
    PeopleOutlineOutlined,
    AccountCircleOutlined,
    FullscreenExitOutlined,
    FullscreenOutlined,
} from '@mui/icons-material';
import { useRole } from '../../../utils/UserProvider';
import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useJwt } from '../../../utils/UserProvider';
import Authentication from '../../user_components/Auth/Authentication';


export const drawerWidth = 240;

export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const pages = [
    { name: 'Accueil', href: '/' },
    { name: 'Annonces', href: '/ads' },
    { name: 'A Propos', href: '/about' },
    { name: 'Contact', href: '/contact' },
]

export const PageDrawer = ({setLoading}) => {
    const { jwt } = useJwt();
    const [openDrawer, setOpenDrawer] = useState(false);

    const [openLogin, setOpenLogin] = useState(false);
    const [openRegister, setOpenRegister] = useState(false);

    const handleLoginOpen = () => {
        setOpenLogin(true);
        setOpenRegister(false);
    };

    const handleRegisterOpen = () => {
        setOpenRegister(true);
        setOpenLogin(false);
    };

    const handleClose = () => {
        setOpenLogin(false);
        setOpenRegister(false);
    };

    return (
        <React.Fragment>
            <IconButton
                sx={{ color: "inherit" }}
                onClick={() => setOpenDrawer(!openDrawer)}
            >
                <MenuIcon />
            </IconButton>
            <DrawerMui open={openDrawer} onClose={() => setOpenDrawer(false)}>
                <List sx={{ pt: 12 }}>
                    {pages.map((page, index) => (
                        <ListItemButton key={index} LinkComponent="a" href={page.href} sx={{ px: 4 }}>
                            <ListItemText primary={page.name} />
                        </ListItemButton>
                    ))}
                    {!jwt &&
                        [
                            <Divider orientation="horizontal" variant="middle" flexItem />
                            ,
                            <ListItemButton
                                LinkComponent="a"
                                onClick={() => setOpenLogin(true)}
                                sx={{ px: 4 }}
                            >
                                <ListItemText primary="Se Connecter" />
                            </ListItemButton>
                            ,
                            <ListItemButton
                                LinkComponent="a"
                                onClick={() => setOpenRegister(true)}
                                sx={{ px: 4 }}
                            >
                                <ListItemText primary="S'inscrire" />
                            </ListItemButton>
                        ]
                    }
                </List>

                <Authentication setLoading={setLoading} openLogin={openLogin} openRegister={openRegister} handleLoginOpen={handleLoginOpen}
                    handleRegisterOpen={handleRegisterOpen} handleClose={handleClose} />
            </DrawerMui>
        </React.Fragment>
    )
}



const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const Drawer = styled(DrawerMui, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const AntTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-flexContainer': {
        width: 'fit-content',
        padding: '2px 3px',
        borderRadius: 4,
    },
});
const AntTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
    width: 200,
    minHeight: 48,
    borderRadius: 4,
    justifyContent: 'initial',
    alignItems: 'end',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 15,
    color: 'rgba(0, 0, 0, 0.7)',
    '&:hover': {
        color: 'var(--primary)',
        opacity: 1,
    },
    '&.Mui-selected': {
        color: 'var(--primary)',
        // backgroundColor: 'rgba(15, 178, 207, 0.1)',
        // borderBottom: '1px solid rgba(15, 178, 207, 1)',
        fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff',
    },
    '& .MuiTab-wrapper': {
        display: 'flex',
        alignItems: 'center',
    },
    '& .MuiSvgIcon-root': {
        minWidth: 0,
        marginRight: '20px',
        justifyContent: 'center',
    },
}));

export const MainDrawer = ({ setLoading, open, setOpen }) => {
    const { role } = useRole();
    const location = useLocation();

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const routeToTabIndex = [
        {
            label: "Tableau de bord",
            href: "/dashboard",
            index: (role === 'ADMIN' || role === 'COORDINATOR') && 0,
            icon: <Tooltip title="Tableau de bord"><DashboardOutlined /></Tooltip>,
            role: 'coordinator',
        },
        {
            label: "Annonces",
            href: "/ads/manage",
            index: (role === 'ADMIN' || role === 'COORDINATOR') && 1,
            icon: <Tooltip title="Annonces"><VolumeDownOutlined /></Tooltip>,
            role: 'coordinator',
        },
        {
            label: "Offres",
            href: "/offers/manage",
            index: (role === 'ADMIN' || role === 'COORDINATOR') && 2,
            icon: <Tooltip title="Offres"><LocalOfferOutlined /></Tooltip>,
            role: 'coordinator',
        },
        {
            label: "Relais",
            href: "/relays/manage",
            index: (role === 'ADMIN' || role === 'COORDINATOR') && 3,
            icon: <Tooltip title="Relais"><Storefront /></Tooltip>,
            role: 'coordinator',
        },
        {
            label: "Utilisateurs",
            href: "/users/manage",
            icon: <Tooltip title="Utilisateurs"><PeopleOutlineOutlined /></Tooltip>,
            index: role === 'ADMIN' && 4,
            role: 'admin',
        },
        {
            label: "Compte",
            href: "/user/profile",
            icon: <Tooltip title="Compte"><AccountCircleOutlined /></Tooltip>,
            index: role === 'USER' ? 0 : role === 'COORDINATOR' ? 4 : role === 'ADMIN' && 5,
            role: 'all',
        },
        {
            label: "Mes Annonces",
            href: "/user/ads/published",
            index: role === 'USER' && 1,
            icon: <Tooltip title="Mes Annonces"><VolumeDownOutlined /></Tooltip>,
            role: 'user',
        },
        {
            label: "Mes Réponses",
            href: "/user/ads/responses",
            index: role === 'USER' && 2,
            icon: <Tooltip title="Mes Réponses"><LocalOfferOutlined /></Tooltip>,
            role: 'user',
        },
    ];

    // Helper function to convert the current path to a tab index
    const getCurrentTabValue = () => {
        var route = location.pathname;
        if (location.pathname.startsWith('/relays')) {
            route = '/relays/manage';
        }
        if (
            (location.pathname.startsWith('/ads') ||
                location.pathname.startsWith('/user/ads')) &&
            role !== 'USER'
        ) {
            route = '/ads/manage';
        }
        if (location.pathname.startsWith('/offers')) {
            route = '/offers/manage';
        }
        if (location.pathname.startsWith('/users')) {
            route = '/users/manage';
        }
        return (routeToTabIndex.find((r) => r.href === route) && routeToTabIndex.find((r) => r.href === route).index) || 0;
    };

    const [value, setValue] = useState(getCurrentTabValue());

    useEffect(() => {
        setLoading(true);
        setValue(getCurrentTabValue());
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]); // Re-run this effect when the location changes

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Drawer variant="permanent" open={open}>
            <DrawerHeader />
            <Divider />
            <Tooltip title="Agrandir">
                <IconButton
                    color='inherit'
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                        mt: 5,
                        ml: 1.4,
                        width: 40,
                        justifyContent: 'flex-start',
                        ...(open && { display: 'none' }),
                    }}
                >
                    {/* <HighlightAlt /> */}
                    <FullscreenOutlined />
                </IconButton>
            </Tooltip>
            <IconButton
                color='inherit'
                aria-label="close drawer"
                onClick={handleDrawerClose}
                edge="start"
                sx={{
                    mt: 5,
                    ml: 1.4,
                    width: 40,
                    justifyContent: 'flex-start',
                    ...(!open && { display: 'none' }),
                }}
            >
                <FullscreenExitOutlined />
                <Typography variant='body2' sx={{ ml: '30px' }}>Réduire</Typography>
            </IconButton>
            <AntTabs
                value={value}
                onChange={handleChange}
                orientation='vertical'
                variant='scrollable'
                visibleScrollbar={false}
                scrollButtons={false}
            >
                {role && (role === 'COORDINATOR' || role === 'ADMIN') &&
                    routeToTabIndex.filter((item) => { return item.role === 'coordinator' }).map(
                        (route) => [
                            <AntTab
                                component={Link} label={route.label}
                                to={route.href}
                                icon={
                                    <Box
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            // color: 'rgba(0, 0, 0, 0.65)'
                                        }}
                                    >
                                        {route.icon}
                                    </Box>
                                }
                                iconPosition="start"
                            />,
                        ]
                    )
                }
                {role && role === 'ADMIN' &&
                    routeToTabIndex.filter((item) => { return item.role === 'admin' }).map(
                        (route) => [
                            <AntTab
                                component={Link} label={route.label}
                                to={route.href}
                                icon={
                                    <Box
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            // color: 'rgba(0, 0, 0, 0.65)'
                                        }}
                                    >
                                        {route.icon}
                                    </Box>
                                }
                                iconPosition="start"
                            />,
                        ]
                    )
                }
                {routeToTabIndex.filter((item) => { return item.role === 'all' }).map(
                    (route) => [
                        <AntTab
                            component={Link} label={route.label}
                            to={route.href}
                            icon={
                                <Box
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                        // color: 'rgba(0, 0, 0, 0.65)',
                                    }}
                                >
                                    {route.icon}
                                </Box>
                            }
                            iconPosition="start"
                        />,
                    ]
                )}
                {role && role === 'USER' &&
                    routeToTabIndex.filter((item) => { return item.role === 'user' }).map(
                        (route) => [
                            <AntTab
                                component={Link} label={route.label}
                                to={route.href}
                                icon={
                                    <Box
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            // color: 'rgba(0, 0, 0, 0.6)'
                                        }}
                                    >
                                        {route.icon}
                                    </Box>
                                }
                                iconPosition="start"
                            />,
                        ]
                    )
                }
            </AntTabs>
            {/* <List>
            {['Dashboard', 'Starred', 'Send email'].map((text, index) => (
                <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                        </ListItemIcon>
                        <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
            ))}
        </List> */}
        </Drawer>
    )
}
import React, { useState } from 'react'
import { Stack } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PhoneNumberField = ({ phone, update }) => {

    const regex = /^\+?[()\d\- ]{12,20}$/;

    const [valid, setValid] = useState(true);
    const handleChangePhone = (value) => {
        setValid(regex.test(value));
        update('phone', `+${value.substring(0, 3)} ${value.substring(3)}`);
    }

    return (
        <Stack direction='column' spacing={1}>
            <PhoneInput
                key='phoneInput'
                country="ma"
                regions={'africa'}
                label="Numéro de téléphone"
                value={phone}
                onChange={handleChangePhone}
                isValid={v => regex.test(v)}
                inputStyle={{
                    width: '100%',
                    height: '55px',
                    fontSize: 14.5,
                    paddingLeft: '45px',
                    borderRightRadius: '8px'
                }}
                inputExtraProps={{
                    required: true,
                    autoFocus: true
                }}
            />
            {!valid && (
                <p key='validMsg' style={{ color: '#d21404', fontSize: 12 }}>
                    Entrez un numéro valide
                </p>
            )}
        </Stack>
    )
}

export default PhoneNumberField


    // const initialState = {
    //     regionCode: phone ? phone.substring(1, 4) : "",
    //     phoneNumber: phone ? phone.substring(5) : "",
    // }
    // const [request, setRequest] = useState(initialState);
    // const [error, setError] = useState({
    //     regionCode: '',
    //     phoneNumber: '',
    // });
    // const phoneRef = useRef();
    // phoneRef.current = initialState;

    // const updateError = (prop, value) => {
    //     setError(prev => ({
    //         ...error,
    //         [prop]: value
    //     }));
    // }

    // const updatePhone = (prop, value) => {
    //     setRequest(prev => ({
    //         ...prev,
    //         [prop]: value
    //     }));
    //     if (prop === 'regionCode')
    //         phoneRef.current.regionCode = value;
    //     if (prop === 'phoneNumber')
    //         phoneRef.current.phoneNumber = value;

    //     const updatedPhone = `+${phoneRef.current.regionCode} ${phoneRef.current.phoneNumber}`;
    //     update('phone', updatedPhone);

    //     if (!value) {
    //         updateError(prop, "Ce champ est obligatoire");
    //     }
    //     else {
    //         updateError(prop, '');
    //     }

    // }


    // {/* <TextField
    //     required
    //     label="Code du pays"
    //     id="regionCode"
    //     value={request.regionCode}
    //     onChange={(e) => updatePhone("regionCode", e.target.value)}
    //     error={error.regionCode !== ''}
    //     helperText={error.regionCode !== '' ? error.regionCode : undefined}
    // />
    // <TextField
    //     required
    //     fullWidth
    //     label="Numéro de téléphone"
    //     id="phoneNumber"
    //     value={request.phoneNumber}
    //     onChange={(e) => updatePhone("phoneNumber", e.target.value)}
    //     error={error.phoneNumber !== ''}
    //     helperText={error.phoneNumber !== '' ? error.phoneNumber : undefined}
    // /> */}
import { Button, Container, Grid, Typography } from '@mui/material'
import React from 'react'

const NotFoundPage = () => {
    return (
        <Container maxWidth="md">
            <Grid container spacing={2} sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh'
            }}>
                <Grid xs={6}>
                    <Typography variant="h1">
                        404
                    </Typography>
                    <Typography variant="h4">
                        Page Introuvable
                    </Typography>
                    <Typography variant="h6" sx={{ marginBottom: 5 }}>
                        La page que vous recherchez n'existe pas.
                    </Typography>
                    <Button variant="contained" href='/'>
                        Retourner à la page d'accueil
                    </Button>
                </Grid>
                <Grid xs={6}>
                    <img
                        src="https://static8.depositphotos.com/1431107/919/i/950/depositphotos_9199988-stock-photo-oops-icon.jpg"
                        alt=""
                        width={400} height={250}
                    />
                </Grid>
            </Grid>
        </Container>
    )
}

export default NotFoundPage
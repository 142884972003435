import React from 'react';
import ReactDOM from 'react-dom/client';
import Alert from '../Alert';

const showAlert = (message, type) => {
    const alertContainer = document.createElement('div');
    document.body.appendChild(alertContainer);

    const root = ReactDOM.createRoot(alertContainer);

    const removeAlert = () => {
        if (alertContainer && alertContainer.parentNode) {
            root.unmount();
            alertContainer.parentNode.removeChild(alertContainer);
        }
    };

    root.render(<Alert message={message} type={type} onClose={removeAlert} />);

    setTimeout(removeAlert, 10000); // Remove the alert after 10 seconds
};

export default showAlert;

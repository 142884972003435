import React, { useEffect, useState } from 'react'
import {
  Button,
  Chip,
  Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, InputLabel,
  MenuItem, OutlinedInput, Radio, RadioGroup, Select, Stack, TextField
} from '@mui/material';
import { saveAd } from '../../../services/adService';
import { MobileDatePicker, MobileDateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { getRelays } from '../../../services/relayService';
import { Cancel, CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { getContents } from '../../../services/contentService';
import CreateContentDialog from '../ContentCrud/CreateContentDialog';
import { useJwt } from '../../../utils/UserProvider';
import showAlert from '../../../utils/ShowAlert';

const AdCreateDialog = ({ setLoading, openCreateAd, setOpenCreateAd, setAds }) => {
  const { jwt } = useJwt();
  const [relays, setRelays] = useState([]);

  const initialAd = {
    title: '',
    type_name: '',
    picture: '',
    weight: 23,
    price: 1400,
    note: '',
    departureRelay: null,
    arrivalRelay: null,
    depositDeadline: dayjs('', "YYYY-MM-DD HH:mm"),
    departureDate: '',
    arrivalDate: '',
    stopoversDetails: '',
    luggageNumber: 0,
    luggageDescription: '',
    minDate: '',
    maxDate: '',
    adContentIds: [],
  }
  const [ad, setAd] = useState(initialAd);
  const [contentTypes, setContentTypes] = useState([]);
  const [openCreateContent, setOpenCreateContent] = useState(false);
  const [error, setError] = useState({
    type_name: '',
    weight: '',
    price: '',
    departureRelay: '',
    arrivalRelay: '',
    depositDeadline: '',
    departureDate: '',
    arrivalDate: '',
    luggageNumber: '',
    luggageDescription: '',
    minDate: '',
    maxDate: '',
    adContentIds: '',
  });

  const updateError = (prop, value) => {
    setError(prev => ({
      ...prev,
      [prop]: value
    }));
  }

  const updateAd = (prop, value) => {
    setAd(prevAd => ({
      ...prevAd,
      [prop]: value
    }))
    if (!value) {
      updateError(prop, "Ce champ est obligatoire")
    }
    else {
      updateError(prop, '');
    }
  }


  useEffect(() => {
    const fetchRelays = async () => {
      setLoading(true);
      const relaysData = await getRelays();
      if (relaysData !== null) {
        setRelays([...relaysData]);
      }
      setLoading(false);
    };

    fetchRelays();

  }, [setLoading]);

  useEffect(() => {
    const fetchContents = async () => {
      setLoading(true);
      const contentsData = await getContents();
      if (contentsData !== null) {
        setContentTypes([...contentsData]);
      }
      setLoading(false);
    };

    fetchContents();
  }, [openCreateContent, setLoading]);

  const handleCloseCreateAd = () => {
    setOpenCreateAd(false);
    setAd(initialAd);
  };

  const handleOpenCreateContent = () => {
    setOpenCreateContent(true);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const formattedAd = {
      ...ad,
      adContentIds: ad.adContentIds?.map(content => content.id),
    };
    const savedAd = await saveAd(formattedAd, jwt);
    if (savedAd !== null) {
      setAds(prev => [savedAd, ...prev]);
      showAlert("Annonce créée avec succès et en attente de validation !", 'success');
      handleCloseCreateAd();
    }
    setLoading(false);
  };

  return [
    <Dialog
      open={openCreateAd}
      onClose={handleCloseCreateAd}
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit,
      }}
      maxWidth='sm'
    >
      <DialogTitle>Créer une annonce</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ padding: 2 }}>
          <Grid container item alignItems="center" spacing={1}>
            <Grid item xs={4}>
              <FormLabel component="legend">Type d'annonce*</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <FormControl component="fieldset" required>
                <RadioGroup
                  row
                  aria-label="Type"
                  value={ad.type_name}
                  onChange={(e) => updateAd("type_name", e.target.value)}
                >
                  <FormControlLabel
                    key={1}
                    label="Je vends mes kilos"
                    value="SALE"
                    control={<Radio checked={ad.type_name === 'SALE'} />}
                  />
                  <FormControlLabel
                    key={2}
                    label="J'achète les kilos"
                    value="REQUEST"
                    control={<Radio checked={ad.type_name === 'REQUEST'} />}
                  />
                </RadioGroup>
                {error.type_name && <FormHelperText error>{error.type_name}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormLabel component="legend">Titre</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                size="small"
                label="Titre de l'annonce"
                value={ad.title}
                onChange={(e) => updateAd("title", e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <FormLabel component="legend">Prix (en MAD)*</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <TextField
                disabled
                required
                fullWidth
                name="price"
                size="small"
                id="price"
                type='number'
                inputProps={{ min: 0 }}
                value={ad.price}
                onChange={(e) => updateAd("price", e.target.value)}
                error={error.price !== ''}
                helperText={error.price !== '' ? error.price : ad.type_name === 'SALE' && 'Vous recevrez 1100 MAD'}
                FormHelperTextProps={{ style: { background: 'var(--primary)', color: 'white', fontSize: '110%' } }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container item alignItems="center" spacing={1}>
            <Grid item xs={4}>
              <FormLabel component="legend">Lieu</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <FormControl key="departureRelay" fullWidth required size='small' sx={{ mt: 2 }}>
                <InputLabel id="departure-relay-label">Lieu de dépôt</InputLabel>
                <Select
                  required
                  labelId="departure-relay"
                  id="departureRelay"
                  label="Lieu de dépôt"
                  value={ad.departureRelay ? ad.departureRelay : ''}
                  onChange={(e) => updateAd("departureRelay", e.target.value)}
                >
                  {[
                    <MenuItem key={0} value={null}>
                      <span className='text-gray-500 text-sm'>Selectionnez un relai</span>
                    </MenuItem>
                  ]}
                  {relays?.map((relay) => (
                    ((!ad.arrivalRelay) ||
                      (ad.arrivalRelay && relay.id !== ad.arrivalRelay.id)) &&
                    [
                      <MenuItem key={relay.id} value={relay}>
                        {relay.city}, {relay.country}
                      </MenuItem>
                    ]
                  ))}
                </Select>
                {error.departureRelay && <FormHelperText error>{error.departureRelay}</FormHelperText>}
              </FormControl>
              <FormControl key="arrivalRelay" fullWidth required size='small' sx={{ mt: 2 }}>
                <InputLabel id="arrival-relay-label">Lieu de retrait</InputLabel>
                <Select
                  required
                  labelId="arrival-relay"
                  id="arrivalRelay"
                  label="Lieu de retrait"
                  value={ad.arrivalRelay ? ad.arrivalRelay : ''}
                  onChange={(e) => updateAd("arrivalRelay", e.target.value)}
                >
                  {[
                    <MenuItem key={0} value={null}>
                      <span className='text-gray-500 text-sm'>Selectionnez un relai</span>
                    </MenuItem>
                  ]}
                  {relays?.map((relay) => (
                    ((!ad.departureRelay) ||
                      (ad.departureRelay && relay.id !== ad.departureRelay.id)) &&
                    [
                      <MenuItem key={relay.id} value={relay}>
                        {relay.city}, {relay.country}
                      </MenuItem>
                    ]
                  ))}
                </Select>
                {error.arrivalRelay && <FormHelperText error>{error.arrivalRelay}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container item alignItems="center" spacing={1}>
            <Grid item xs={4}>
              <FormLabel component="legend">Bagage(s)</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <TextField
                key="weight"
                disabled
                required
                fullWidth
                size="small"
                id="weight"
                name="weight"
                type='number'
                label="Poids (en Kilogrammes)"
                inputProps={{ min: 0 }}
                value={ad.weight}
                onChange={(e) => updateAd("weight", e.target.value)}
                error={error.weight !== ''}
                helperText={error.weight !== '' && error.weight}
                sx={{ mt: 3 }}
              />
              {ad.type_name === 'REQUEST' ? (
                <>
                  <TextField
                    key="luggageNumber"
                    required
                    fullWidth
                    size="small"
                    id="luggageNumber"
                    name="luggageNumber"
                    type='number'
                    label="Nombre de bagages"
                    inputProps={{ min: 0 }}
                    value={ad.luggageNumber}
                    onChange={(e) => updateAd("luggageNumber", e.target.value)}
                    error={error.luggageNumber !== ''}
                    helperText={error.luggageNumber !== '' && error.luggageNumber}
                    sx={{ mt: 3 }}
                  />
                  <TextField
                    key="luggageDescription"
                    required
                    fullWidth
                    size="small"
                    id="luggageDescription"
                    name="luggageDescription"
                    label="Description des bagages"
                    value={ad.luggageDescription}
                    onChange={(e) => updateAd("luggageDescription", e.target.value)}
                    error={error.luggageDescription !== ''}
                    helperText={error.luggageDescription !== '' && error.luggageDescription}
                    sx={{ mt: 3 }}
                  />
                  <Button key="addContentBtn" variant='outlined' size='small' onClick={handleOpenCreateContent} sx={{ mt: 3 }}>
                    Ajouter un type de contenu
                  </Button>
                  <FormControl key='adContentIds' size='small' fullWidth required sx={{ mt: 1 }}>
                    <InputLabel>Contenu des bagages</InputLabel>
                    <Select
                      multiple
                      value={ad.adContentIds}
                      onChange={(e) => { updateAd("adContentIds", e.target.value) }}
                      input={<OutlinedInput label="Contenu des bagages" />}
                      renderValue={(selected) => (
                        <Stack gap={1} direction="row" flexWrap="wrap">
                          {selected?.map((value) => (
                            <Chip
                              key={value.id}
                              label={value.designation}
                              onDelete={() =>
                                updateAd("adContentIds", ad.adContentIds.filter((item) => item.id !== value.id))
                              }
                              deleteIcon={
                                <Cancel
                                  onMouseDown={(event) => event.stopPropagation()}
                                />
                              }
                            />
                          ))}
                        </Stack>
                      )}
                    >
                      {contentTypes?.map((content) => [
                        <MenuItem
                          key={content.id}
                          value={content}
                          sx={{ justifyContent: "space-between" }}
                        >
                          {content.designation}
                          {ad.adContentIds.includes(content) ? <CheckBox color="info" /> : <CheckBoxOutlineBlank color="info" />}
                        </MenuItem>
                      ])}
                    </Select>
                    {error.adContentIds && <FormHelperText error>{error.adContentIds}</FormHelperText>}
                  </FormControl>
                </>
              )
                :
                (
                  <TextField
                    key="luggageNumber"
                    required
                    fullWidth
                    size="small"
                    id="luggageNumber"
                    name="luggageNumber"
                    type='number'
                    label="Nombre maximum de bagages"
                    inputProps={{ min: 0 }}
                    value={ad.luggageNumber}
                    onChange={(e) => updateAd("luggageNumber", e.target.value)}
                    error={error.luggageNumber !== ''}
                    helperText={error.luggageNumber !== '' && error.luggageNumber}
                    sx={{ mt: 3 }}
                  />
                )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container item alignItems="center" spacing={1}>
            <Grid item xs={4}>
              <FormLabel component="legend">Dates</FormLabel>
            </Grid>
            {ad.type_name === 'SALE' &&
              <Grid item xs={8}>
                <MobileDatePicker
                  key='departureDate'
                  required
                  id="departureDate"
                  name="departureDate"
                  label="Date de départ *"
                  format='YYYY-MM-DD'
                  disablePast
                  maxDate={ad.arrivalDate}
                  value={ad.departureDate}
                  onChange={(newValue) => { updateAd("departureDate", newValue); }}
                  error={error.departureDate !== ''}
                  helperText={error.departureDate !== '' && error.departureDate}
                  sx={{ width: '100%', mt: 2 }}
                  slotProps={{ textField: { size: 'small' } }}
                />
                <MobileDatePicker
                  key='arrivalDate'
                  required
                  id="arrivalDate"
                  name="arrivalDate"
                  format='YYYY-MM-DD'
                  label="Date d'arrivée *"
                  minDate={ad.departureDate}
                  value={ad.arrivalDate}
                  onChange={(newValue) => { updateAd("arrivalDate", newValue); }}
                  error={error.arrivalDate !== ''}
                  helperText={error.arrivalDate !== '' && error.arrivalDate}
                  sx={{ width: '100%', mt: 3 }}
                  slotProps={{ textField: { size: 'small' } }}
                />
                <MobileDateTimePicker
                  key='depositDeadline'
                  required
                  id="depositDeadline"
                  name="depositDeadline"
                  label="Délai de dépôt au lieu de dépôt *"
                  format='YYYY-MM-DD HH:mm'
                  ampm={false}
                  minTime={dayjs('00:00', 'HH:mm')}
                  maxTime={dayjs('23:59', 'HH:mm')}
                  minDate={dayjs()}
                  maxDate={ad.departureDate}
                  value={ad.depositDeadline}
                  onChange={(newValue) => { updateAd("depositDeadline", dayjs(newValue).format("YYYY-MM-DD HH:mm")); }}
                  error={error.depositDeadline !== ''}
                  helperText={error.depositDeadline !== '' && error.depositDeadline}
                  sx={{ width: '100%', mt: 3 }}
                  slotProps={{ textField: { size: 'small' } }}
                />
              </Grid>
            }
            {ad.type_name === 'REQUEST' &&
              <Grid item xs={8}>
                <MobileDatePicker
                  key='minDate'
                  required
                  id="minDate"
                  name="minDate"
                  label="Date minimum *"
                  format='YYYY-MM-DD'
                  disablePast
                  maxDate={ad.maxDate}
                  value={ad.minDate}
                  onChange={(newValue) => { updateAd("minDate", newValue); }}
                  error={error.minDate !== ''}
                  helperText={error.minDate !== '' && error.minDate}
                  sx={{ width: '100%', mt: 2 }}
                  slotProps={{ textField: { size: 'small' } }}
                />
                <MobileDatePicker
                  key='maxDate'
                  required
                  id="maxDate"
                  name="maxDate"
                  label="Date maximum *"
                  format='YYYY-MM-DD'
                  minDate={ad.minDate}
                  value={ad.maxDate}
                  onChange={(newValue) => { updateAd("maxDate", newValue); }}
                  error={error.maxDate !== ''}
                  helperText={error.maxDate !== '' && error.maxDate}
                  sx={{ width: '100%', mt: 3 }}
                  slotProps={{ textField: { size: 'small' } }}
                />
              </Grid>
            }
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container item alignItems="center" spacing={1}>
            <Grid item xs={4}>
              <FormLabel component="legend">Notes</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="note"
                fullWidth
                name="note"
                rows={3}
                helperText="Mentionnez des conditions ou un message pour l'acheteur"
                value={ad.note}
                onChange={(e) => updateAd("note", e.target.value)}
              />
            </Grid>
            {ad.type_name === 'SALE' &&
              <>
                <Grid item xs={4}>
                  <FormLabel component="legend">Escales</FormLabel>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    id="stopovers"
                    fullWidth
                    name="stopovers"
                    rows={3}
                    helperText="Nombre et lieu de(s) stop(s) (Ne sera affiché qu'au coordinateur)"
                    defaultValue={ad.stopovers}
                    onChange={(e) => updateAd("stopovers", e.target.value)}
                  />
                </Grid>
              </>
            }
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={handleCloseCreateAd}>Annuler</Button>
        <Button variant='contained' onClick={(e) => {
          e.preventDefault();
          handleSubmit();
        }}>
          Enregistrer l'annonce
        </Button>
      </DialogActions>
    </Dialog>
    ,
    <CreateContentDialog setLoading={setLoading} openCreateContent={openCreateContent} setOpenCreateContent={setOpenCreateContent} />
  ]
}

export default AdCreateDialog
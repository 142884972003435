import { React, Fragment, useState, useEffect } from 'react'
import { Transition } from '@headlessui/react'
import { Dialog, Menu } from '@headlessui/react'
import AdCard from './AdCard'
import { Close, FilterAlt, KeyboardArrowDown } from '@mui/icons-material'
import Footer from '../Layouts/Footer'
import { getPublishedAds, searchAds } from '../../../services/adService'
import {
  Box, Divider, Grid, IconButton, Typography, useMediaQuery, CssBaseline, Tooltip
} from '@mui/material'
import { getRelays } from '../../../services/relayService'
import { useLocation, useNavigate } from 'react-router-dom'
import AdSearchForm from './AdSearchForm'
import { useRole } from '../../../utils/UserProvider'
import NavBar from '../Layouts/NavBar'
import { MainDrawer, DrawerHeader } from '../Layouts/Drawer'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Ads = ({ setLoading }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { role } = useRole();
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)

  const [ads, setAds] = useState([]);
  const [relays, setRelays] = useState([]);
  const [adsSearched, setAdsSearched] = useState([]);
  const initialSearchValues = {
    type: '',
    departureRelay: null,
    arrivalRelay: null,
  }
  const [adSearchRequest, setAdSearchRequest] = useState(initialSearchValues);
  const updateAdSearch = (prop, value) => {
    setAdSearchRequest(prevAd => ({
      ...prevAd,
      [prop]: value
    }));
  }

  useEffect(() => {
    const fetchRelays = async () => {
      setLoading(true);
      const relaysData = await getRelays();
      setRelays(relaysData);
      setLoading(false);
    };
    fetchRelays();
    const fetchAds = async () => {
      setLoading(true);
      const adsData = await getPublishedAds();
      if (adsData !== null) {
        setAds(adsData.sort((a, b) => new Date(b.publicationDate) - new Date(a.publicationDate)));
      }
      setLoading(false);
    };
    fetchAds();
  }, [setLoading]);

  useEffect(() => {
    setLoading(true);
    const searchParams = new URLSearchParams(location.search);
    const data = searchParams.get('ads-data');

    if (data !== null) {
      if (data) {
        const rawData = localStorage.getItem('adsSearch');
        try {
          const data = JSON.parse(rawData);
          // console.log('search result', data);
          setAdsSearched(data);
        } catch (parseError) {
          console.error("Error parsing adsSearch from localStorage:", parseError);
        }
      }
      else {
        setAdsSearched([]);
      }
    } else {
      localStorage.removeItem('adsSearch');
    }
    setLoading(false);

  }, [location, navigate, setLoading]);

  useEffect(() => {
    const search = async () => {
      setLoading(true);
      const ads = await searchAds(adSearchRequest);
      if (ads !== null) {
        const adsPublished = [];
        ads.forEach(ad => {
          if (ad.status === 'PUBLISHED') {
            adsPublished.push(ad);
          }
        });
        localStorage.setItem('adsSearch', JSON.stringify(adsPublished));
        setAdsSearched(adsPublished);
      }
      setLoading(false);
    };
    if (adSearchRequest.type || adSearchRequest.departureRelay || adSearchRequest.arrivalRelay) {
      search();
    }
  }, [adSearchRequest, setLoading]);

  // const handleSubmitSearch = async () => {
  //   try {
  //     const ads = await searchAds(adSearchRequest);
  //     localStorage.setItem('adsSearch', JSON.stringify(ads));
  //     // window.location.href = `/ads?ads-data=${encodeURIComponent(ads)}`;
  //     setAdsSearched(ads);
  //     setMobileFiltersOpen(false);
  //   }
  //   catch (error) {
  //     console.error("Failed to save ad:", error);
  //   }
  // };

  const handleSortAdsAscending = () => {
    setLoading(true);
    if (adsSearched && adsSearched.length > 0) {
      const sortedAds = [...adsSearched].sort((a, b) => new Date(a.publicationDate) - new Date(b.publicationDate));
      setAdsSearched(sortedAds);
    } else {
      const sortedAds = [...ads].sort((a, b) => new Date(a.publicationDate) - new Date(b.publicationDate));
      setAds(sortedAds);
    }
    setLoading(false);
  };

  const handleSortAdsDescending = () => {
    setLoading(true);
    if (adsSearched && adsSearched.length > 0) {
      const sortedAds = [...adsSearched].sort((a, b) => new Date(b.publicationDate) - new Date(a.publicationDate));
      setAdsSearched(sortedAds);
    } else {
      const sortedAds = [...ads].sort((a, b) => new Date(b.publicationDate) - new Date(a.publicationDate));
      setAds(sortedAds);
    }
    setLoading(false);
  };


  const handleResetSearch = () => {
    setLoading(true);
    setAdSearchRequest(initialSearchValues);
    setMobileFiltersOpen(false);
    localStorage.removeItem('adsSearch')
    window.location.href = '/ads'
    setLoading(false);
  }
  
  const [open, setOpen] = useState(false);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <NavBar setLoading={setLoading} open={open} setOpen={setOpen} />
      {(role === 'ADMIN' || role === 'COORDINATOR') && <MainDrawer setLoading={setLoading} open={open} setOpen={setOpen} />}
      <Box sx={{ flexGrow: 1 }}>
        <Box component="main" sx={{ p: 3, mt: 3 }}>
          <DrawerHeader />
          {/* Mobile filter dialog */}
          <Transition.Root show={mobileFiltersOpen} as={Fragment}>
            <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white pt-28 pb-12 shadow-xl">
                    <div className="flex items-center justify-between mx-4 pb-3 border-b border-gray-300">
                      <Typography variant='h6' color='gray'>Filtres</Typography>
                      <IconButton
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Tooltip Content"
                        onClick={() => setMobileFiltersOpen(false)}
                      >
                        <Close className="h-6 w-6" aria-hidden="true" />
                      </IconButton>
                    </div>
                    <div className="m-5">
                      <AdSearchForm adSearchRequest={adSearchRequest} updateAdSearch={updateAdSearch}
                        relays={relays} handleResetSearch={handleResetSearch}
                      />
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-5">
              <Typography variant='h5'>Annonces</Typography>

              <div className="flex items-center">
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                      Trier
                      <KeyboardArrowDown
                        className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item key={0}>
                          {({ active }) => (
                            <Typography
                              onClick={handleSortAdsDescending}
                              variant='body2'
                              component='div'
                              // sx={{
                              //   color: active ? 'GrayText' : 'black',
                              //   cursor: 'pointer',
                              //   px: 3,
                              //   py: 2,
                              // }}
                              className={classNames(
                                'font-medium text-gray-900',
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm cursor-pointer'
                              )}
                            >
                              Plus récents
                            </Typography>
                          )}
                        </Menu.Item>
                        <Menu.Item key={1}>
                          {({ active }) => (
                            <Typography
                              onClick={handleSortAdsAscending}
                              variant='body2'
                              component='div'
                              // sx={{
                              //   // fontWeight: active ? 'bold' : 'none',
                              //   color: active ? 'GrayText' : 'black',
                              //   cursor: 'pointer',
                              //   px: 3,
                              //   py: 2,
                              // }}
                              className={classNames(
                                'font-medium text-gray-900',
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm cursor-pointer'
                              )}
                            >
                              Plus ancient
                            </Typography>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
                <button
                  type="button"
                  className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                  onClick={() => setMobileFiltersOpen(true)}
                >
                  <Tooltip title="Filtres">
                    <FilterAlt sx={{ h: 5, w: 5 }} />
                  </Tooltip>
                </button>
              </div>
            </div>
            <Grid container justifyContent='center' spacing={2} sx={{ py: 2 }}>
              {isLargeScreen && (
                <>
                  <Grid item xs={12} lg={2.1} >
                    <Box
                      sx={{
                        position: 'static',
                        top: 80,
                        maxHeight: 'auto', // Adjust height as needed
                        overflowY: "auto",
                        scrollbarWidth: 'none',
                        backgroundColor: 'white',
                      }}
                    >
                      {/* Sidebar content */}
                      <Typography variant="h6" color='GrayText' sx={{ pb: 2 }}>
                        Filtres
                      </Typography>
                      <div className='hidden lg:block'>
                        <AdSearchForm adSearchRequest={adSearchRequest} updateAdSearch={updateAdSearch}
                          relays={relays} handleResetSearch={handleResetSearch}
                        />
                      </div>
                    </Box>
                  </Grid>
                  <Grid item lg={0.5} >
                    <Divider orientation='vertical' />
                  </Grid>
                </>
              )}
              {(adsSearched && (Object.keys(adsSearched).length > 0)) ?
                (
                  <Grid item container spacing={2} xs={12} lg={9.4} >
                    {adsSearched.map((item) =>
                      <Grid item xs={12} sm={6} md={4} key={item.id}
                      >
                        <AdCard ad={item} />
                      </Grid>
                    )}
                  </Grid>

                ) : (
                  (localStorage.getItem('adsSearch') === null &&
                    (ads && (Object.keys(ads).length > 0))) ?
                    (
                      <Grid item xs={12} lg={9.4} container spacing={2} >
                        {ads.map((item) =>
                          <Grid item xs={12} sm={6} md={4} key={item.id}
                          >
                            <AdCard ad={item} />
                          </Grid>
                        )}
                      </Grid>
                    ) : (
                      <Grid item xs={12} lg={9.4} container spacing={2} >
                        <Grid item xs={12} textAlign='center'>
                          <Typography variant='body1' color='GrayText' fontSize='22px' fontWeight='normal' sx={{ my: 4 }}>
                            Aucune Annonce trouvée.
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        {ads.map((item) =>
                          <Grid item xs={12} sm={6} md={4} key={item.id}
                          >
                            <AdCard ad={item} />
                          </Grid>
                        )}
                      </Grid>
                    )
                )
              }
            </Grid>
          </div >
        </Box>
        <Footer />
      </Box>
    </Box>
  );
}

export default Ads
import "./App.css";
import { Route, Routes } from "react-router-dom";
import React, { useState } from "react";
import HomePage from "./components/public_components/Pages/HomePage";
import AboutPage from "./components/public_components/Pages/AboutPage";
import ContactPage from "./components/public_components/Pages/ContactPage";
import Ads from "./components/public_components/Ads/Ads";
import AdDetails from "./components/public_components/Ads/AdDetails";
import ForgotPassword from "./components/user_components/Auth/ForgotPassword";
import UserPage from "./components/user_components/Auth/UserPage";
import ManageAds from "./components/coordinator_components/AdCrud/ManageAds";
import AdsPublished from "./components/user_components/AdCrud/AdsPublished";
import ManageUsers from "./components/admin_components/Users/ManageUsers";
import PrivateRoute from "./routers/PrivateRoute";
import ManageRelays from "./components/coordinator_components/RelayCrud/ManageRelay";
import RelayView from "./components/coordinator_components/RelayCrud/RelayView";
import AdView from "./components/user_components/AdCrud/AdView";
import BidderOffers from "./components/user_components/Offers/BidderOffers";
import NotFoundPage from "./components/public_components/Pages/NotFoundPage";
import ManageOffers from "./components/coordinator_components/OfferCrud/ManageOffers";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ForbiddenPage from "./components/public_components/Pages/ForbiddenPage";
import { useRole } from "./utils/UserProvider";
import Dashboard from "./components/coordinator_components/Dashboard/Dashboard";
import ResetPassword from "./components/user_components/Auth/ResetPassword";
import Preloader from "./components/Preloader/Preloader";

function App() {
  const [loading, setLoading] = useState(false);
  const { role } = useRole();

  return (
    <div>
      {loading && <Preloader show={loading} />}
      <Routes>
        {/* Authentication */}
        <Route
          path="/forgot-password"
          element={<ForgotPassword setLoading={setLoading} />}
        />
        <Route
          path="/reset-password"
          element={<ResetPassword setLoading={setLoading} />}
        />
        <Route path="/404" element={<NotFoundPage />} />
        <Route path="/403" element={<ForbiddenPage />} />

        {/* Public Routes */}
        <Route path="/" element={<HomePage setLoading={setLoading} />} />
        <Route path="/about" element={<AboutPage setLoading={setLoading} />} />
        <Route
          path="/contact"
          element={<ContactPage setLoading={setLoading} />}
        />
        <Route path="/ads" element={<Ads setLoading={setLoading} />} />
        <Route
          path="/ads/:adId"
          element={
            <PrivateRoute setLoading={setLoading}>
              <AdDetails setLoading={setLoading} />
            </PrivateRoute>
          }
        />

        {/* User Routes */}
        <Route
          path="/user/profile"
          element={
            <PrivateRoute setLoading={setLoading}>
              <UserPage setLoading={setLoading} />
            </PrivateRoute>
          }
        />
        <Route
          path="/user/ads/:id"
          element={
            <PrivateRoute setLoading={setLoading}>
              <AdView setLoading={setLoading} />
            </PrivateRoute>
          }
        />
        <Route
          path="/user/ads/published"
          element={
            <PrivateRoute setLoading={setLoading}>
              <AdsPublished setLoading={setLoading} />
            </PrivateRoute>
          }
        />
        <Route
          path="/user/ads/responses"
          element={
            <PrivateRoute setLoading={setLoading}>
              <BidderOffers setLoading={setLoading} />
            </PrivateRoute>
          }
        />

        {/* Coordinator Routes */}
        <Route
          path="/dashboard"
          element={
            role === "COORDINATOR" || role === "ADMIN" ? (
              <PrivateRoute setLoading={setLoading}>
                <Dashboard setLoading={setLoading} />
              </PrivateRoute>
            ) : (
              <PrivateRoute setLoading={setLoading}>
                <ForbiddenPage />
              </PrivateRoute>
            )
          }
        />
        <Route
          path="/relays/manage"
          element={
            role === "COORDINATOR" || role === "ADMIN" ? (
              <PrivateRoute setLoading={setLoading}>
                <ManageRelays setLoading={setLoading} />
              </PrivateRoute>
            ) : (
              <PrivateRoute setLoading={setLoading}>
                <ForbiddenPage />
              </PrivateRoute>
            )
          }
        />
        <Route
          path="/relays/:id"
          element={
            role === "COORDINATOR" || role === "ADMIN" ? (
              <PrivateRoute setLoading={setLoading}>
                <RelayView setLoading={setLoading} />
              </PrivateRoute>
            ) : (
              <PrivateRoute setLoading={setLoading}>
                <ForbiddenPage />
              </PrivateRoute>
            )
          }
        />
        <Route
          path="/ads/manage"
          element={
            role === "COORDINATOR" || role === "ADMIN" ? (
              <PrivateRoute setLoading={setLoading}>
                <ManageAds setLoading={setLoading} />
              </PrivateRoute>
            ) : (
              <PrivateRoute setLoading={setLoading}>
                <ForbiddenPage />
              </PrivateRoute>
            )
          }
        />
        <Route
          path="/offers/manage"
          element={
            role === "COORDINATOR" || role === "ADMIN" ? (
              <PrivateRoute setLoading={setLoading}>
                <ManageOffers setLoading={setLoading} />
              </PrivateRoute>
            ) : (
              <PrivateRoute setLoading={setLoading}>
                <ForbiddenPage />
              </PrivateRoute>
            )
          }
        />

        {/* Admin Routes */}
        <Route
          path="/users/manage"
          element={
            role === "ADMIN" ? (
              <PrivateRoute setLoading={setLoading}>
                <ManageUsers setLoading={setLoading} />
              </PrivateRoute>
            ) : (
              <PrivateRoute setLoading={setLoading}>
                <ForbiddenPage />
              </PrivateRoute>
            )
          }
        />
      </Routes>
    </div>
  );
}

export default App;

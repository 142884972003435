import ajax from "./ajax";

export const sendNotification = async (request, jwt) => {
  return ajax("/api/notifications/send", "POST", jwt, request);
};

export const getNotifications = async (recipientId, jwt) => {
  return ajax(`/api/notifications/${recipientId}`, "GET", jwt);
};

export const readNotification = async (id, jwt) => {
  return ajax(`/api/notifications/read/${id}`, "POST", jwt, null, true);
};

export const readAllNotifications = async (recipientId, jwt) => {
  return ajax(`/api/notifications/read-all/${recipientId}`, "POST", jwt, null, true);
};

export const contactMessage = async (request) => {
  return ajax(`/api/notifications/contact`, "POST", null, request);
};

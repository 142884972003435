import { frFR } from "@mui/material/locale";
import { createTheme } from "@mui/material/styles";
import { frFR as frFR_dataGrid} from "@mui/x-data-grid";
import { frFR as frFR_picker } from "@mui/x-date-pickers";

export default createTheme(
  {
    palette: {
      primary: {
        main: "#0fb2cf",
        light: "#12c7e7",
        dark: "#1396ad",
        contrastText: "#fff",
      },
      secondary: {
        main: "#0b89d7",
        light: "#0f9df5",
        dark: "#0f7cc0",
        contrastText: "#fff",
      },
      dark: {
        main: "#0d7f93",
        light: "#0f8da3",
        dark: "#0a5d6c",
        contrastText: "#fff",
      },
    },
    components: {
      MuiStack: {
        defaultProps: {
          useFlexGap: true,
        },
      },
    },

    typography: {
      h4: {
        fontSize: "1.8rem",
        fontWeight: 700,
      },
      h5: {
        fontSize: "1.4rem",
        fontWeight: 600,
      },
      h6: {
        fontSize: "1rem",
        fontWeight: 600,
      },
    },
  },
  frFR_picker,
  frFR_dataGrid,
  frFR
);

import React, { useEffect, useRef, useState } from 'react'
import {
  Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl,
  FormLabel, Grid, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField
} from '@mui/material';
import { updateAd } from '../../../services/adService';
import { MobileDatePicker, MobileDateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { getRelays } from '../../../services/relayService';
import { Cancel, CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { getContents } from '../../../services/contentService';
import CreateContentDialog from '../ContentCrud/CreateContentDialog';
import { useJwt } from '../../../utils/UserProvider';
import Preloader from '../../Preloader/Preloader';
import showAlert from '../../../utils/ShowAlert';

const AdEditDialogUser = ({ setLoading, adId, ad, setAd, openUpdateAd, setOpenUpdateAd, url }) => {
  const { jwt } = useJwt();

  const [relays, setRelays] = useState([]);
  const [contentTypes, setContentTypes] = useState([]);
  const [openCreateContent, setOpenCreateContent] = useState(false);
  const adRef = useRef();
  adRef.current = ad;
  const [updatedAd, setUpdatedAd] = useState(adRef.current);
  const [error, setError] = useState({
    type: '',
    weight: '',
    price: '',
    departureRelay: '',
    arrivalRelay: '',
    depositDeadline: '',
    departureDate: '',
    arrivalDate: '',
    luggageNumber: '',
    luggageDescription: '',
    minDate: '',
    maxDate: '',
    adContents: '',
  });

  const updateError = (prop, value) => {
    setError(prev => ({
      ...error,
      [prop]: value
    }));
  }

  const updateAdProp = (prop, value) => {
    setUpdatedAd(prevAd => ({
      ...prevAd,
      [prop]: value
    }))
    if (!value) {
      updateError(prop, "Ce champ est obligatoire")
    }
    else {
      updateError(prop, '');
    }
  }

  // useEffect(() => {
  //   console.log("Fetch ad by id")
  //   const fetchAd = async () => {
  //     const adData = await getAdById(adId, jwt);
  //     if (adData !== null) {
  //       console.log(adData)
  //       setUpdatedAd(adData);
  //     }
  //   };

  //   if (jwt) {
  //     fetchAd();
  //   }

  // }, [adId, setAd, jwt])

  useEffect(() => {
    const fetchRelays = async () => {
      setLoading(true);
      const relaysData = await getRelays();
      setRelays(relaysData);
      setLoading(false);
    };

    fetchRelays();
  }, [setLoading]);

  useEffect(() => {
    const fetchContents = async () => {
      setLoading(true);
      const contentsData = await getContents();
      setContentTypes(contentsData);
      setLoading(false);
    };

    fetchContents();
  }, [openCreateContent, setLoading]);

  const handleCloseUpdateAd = () => {
    setOpenUpdateAd(false);
  };

  const handleOpenCreateContent = () => {
    setOpenCreateContent(true);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const formattedAd = {
      ...updatedAd,
      adContentIds: updatedAd.adContents && updatedAd.adContents.map(content => content.id),
      minDate: dayjs(updatedAd.minDate).format("YYYY-MM-DD"),
      maxDate: dayjs(updatedAd.maxDate).format("YYYY-MM-DD"),
      departureDate: dayjs(updatedAd.departureDate).format("YYYY-MM-DD"),
      arrivalDate: dayjs(updatedAd.arrivalDate).format("YYYY-MM-DD"),
      depositDeadline: dayjs(updatedAd.depositDeadline).format("YYYY-MM-DD HH:mm"),
    };
    const savedAd = await updateAd(formattedAd, jwt);
    if (savedAd !== null) {
      setAd({ ...savedAd });
      handleCloseUpdateAd();
      showAlert("Modification Effectuée !", 'success');
    }
    setLoading(false);
  };

  return (
    [
      <Dialog
        open={openUpdateAd}
        onClose={handleCloseUpdateAd}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit,
        }}
        maxWidth='sm'
      >
        <DialogTitle>Modifier l'annonce</DialogTitle>
        <DialogContent>
          {updatedAd &&

            <Grid container spacing={2} sx={{ padding: 2 }}>
              <Grid container item alignItems="center" spacing={1}>
                <Grid item xs={4}>
                  <FormLabel component="legend">Lieu</FormLabel>
                </Grid>
                <Grid item xs={8}>
                  <div className='mt-2'>
                    <FormControl fullWidth required size='small'>
                      <InputLabel id="departure-relay-label">lieu de dépôt</InputLabel>
                      <Select
                        required
                        labelId="departure-relay"
                        id="departureRelay"
                        label="Lieu de dépôt"
                        value={updatedAd.departureRelay ? updatedAd.departureRelay : ''}
                        onChange={(e) => updateAdProp("departureRelay", e.target.value)}
                        error={error.departureRelay !== ''}
                        helpertext={error.departureRelay !== '' ? error.departureRelay : undefined}

                      >
                        {[
                          <MenuItem key={0} value={updatedAd.departureRelay}>
                            <span>{updatedAd.departureRelay.city}, {updatedAd.departureRelay.country}</span>
                          </MenuItem>
                        ]}
                        {relays.map((relay) => (
                          (
                            (updatedAd.departureRelay && relay.id !== updatedAd.departureRelay.id)
                            && (updatedAd.arrivalRelay && relay.id !== updatedAd.arrivalRelay.id)
                          )
                          && [
                            <MenuItem key={relay.id} value={relay}>
                              <span>{relay.city}, {relay.country}</span>
                            </MenuItem>
                          ]
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className='mt-5'>
                    <FormControl fullWidth required size='small'>
                      <InputLabel id="arrival-relay-label">Lieu de retrait</InputLabel>
                      <Select
                        required
                        labelId="arrival-relay"
                        id="arrivalRelay"
                        label="Lieu de retrait"
                        value={updatedAd.arrivalRelay ? updatedAd.arrivalRelay : ''}
                        onChange={(e) => updateAdProp("arrivalRelay", e.target.value)}
                        error={error.arrivalRelay !== ''}
                        helpertext={error.arrivalRelay !== '' ? error.arrivalRelay : undefined}
                      >
                        {[
                          <MenuItem key={0} value={updatedAd.arrivalRelay}>
                            <span>{updatedAd.arrivalRelay.city}, {updatedAd.arrivalRelay.country}</span>
                          </MenuItem>
                        ]}
                        {relays.map((relay) => (
                          (
                            (updatedAd.departureRelay && relay.id !== updatedAd.departureRelay.id)
                            && (updatedAd.arrivalRelay && relay.id !== updatedAd.arrivalRelay.id)
                          )
                          && [
                            <MenuItem key={relay.id} value={relay}>
                              <span>{relay.city}, {relay.country}</span>
                            </MenuItem>
                          ]
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid container item alignItems="center" spacing={1}>
                <Grid item xs={4}>
                  <FormLabel component="legend">Bagage(s)</FormLabel>
                </Grid>
                <Grid item xs={8}>
                  <div className='mt-5'>
                    <TextField
                      disabled
                      required
                      fullWidth
                      size="small"
                      id="weight"
                      name="weight"
                      type='number'
                      label="Poids (en Kilogrammes)"
                      inputProps={{ min: 0 }}
                      value={updatedAd.weight}
                      onChange={(e) => updateAdProp("weight", e.target.value)}
                      error={error.weight !== ''}
                      helperText={error.weight !== '' ? error.weight : undefined}
                    />
                  </div>
                  {updatedAd.type === 'REQUEST' && (
                    [
                      <div className='mt-5'>
                        <TextField
                          required
                          fullWidth
                          size="small"
                          id="luggageNumber"
                          name="luggageNumber"
                          type='number'
                          label="Nombre de bagages"
                          inputProps={{ min: 0 }}
                          value={updatedAd.luggageNumber}
                          onChange={(e) => updateAdProp("luggageNumber", e.target.value)}
                          error={error.luggageNumber !== ''}
                          helperText={error.luggageNumber !== '' ? error.luggageNumber : undefined}
                        />
                      </div>
                      ,
                      <div className='mt-5'>
                        <TextField
                          required
                          fullWidth
                          size="small"
                          id="luggageDescription"
                          name="luggageDescription"
                          label="Description des bagages"
                          value={updatedAd.luggageDescription}
                          onChange={(e) => updateAdProp("luggageDescription", e.target.value)}
                          error={error.luggageDescription !== ''}
                          helperText={error.luggageDescription !== '' ? error.luggageDescription : undefined}
                        />
                      </div>
                      ,
                      <div className='mt-5'>
                        <Button variant='outlined' size='small' onClick={handleOpenCreateContent}>
                          Ajouter un type de contenu
                        </Button>
                      </div>
                      ,
                      <div className='mt-5'>
                        <FormControl size='small' fullWidth>
                          <InputLabel>Contenu</InputLabel>
                          <Select
                            multiple
                            value={updatedAd.adContents}
                            onChange={(e) => { updateAdProp("adContents", e.target.value) }}
                            error={error.adContents !== ''}
                            helpertext={error.adContents !== '' ? error.adContents : undefined}
                            input={<OutlinedInput label="Contenu" />}
                            renderValue={(contentsSelected) => (
                              <Stack gap={1} direction="row" flexWrap="wrap">
                                {contentsSelected.map((content) => (
                                  <Chip
                                    key={content.id}
                                    label={content.designation}
                                    onDelete={() =>
                                      updateAdProp("adContents", updatedAd.adContents.filter((item) => item.id !== content.id))
                                    }
                                    deleteIcon={
                                      <Cancel
                                        onMouseDown={(event) => event.stopPropagation()}
                                      />
                                    }
                                  />
                                ))}
                              </Stack>
                            )}
                          >
                            {contentTypes.map((content) => [
                              <MenuItem
                                key={content.id}
                                value={content}
                                sx={{ justifyContent: "space-between" }}
                              >
                                <span>{content.designation}</span>
                                {updatedAd.adContents.includes(content) ?
                                  <CheckBox color="info" />
                                  :
                                  <CheckBoxOutlineBlank color="info" />
                                }
                              </MenuItem>
                            ])}
                          </Select>
                        </FormControl>
                      </div>
                    ]
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid container item alignItems="center" spacing={1}>
                <Grid item xs={4}>
                  <FormLabel component="legend">Dates</FormLabel>
                </Grid>
                {updatedAd.type === 'SALE' &&
                  <Grid item xs={8}>
                    <div className='mt-2'>
                      <MobileDatePicker
                        required
                        id="departureDate"
                        name="departureDate"
                        label="Date de départ *"
                        format='YYYY-MM-DD'
                        disablePast
                        maxDate={updatedAd.arrivalDate && dayjs(updatedAd.arrivalDate)}
                        value={dayjs(updatedAd.departureDate)}
                        onChange={(newValue) => { updateAdProp("departureDate", newValue); }}
                        error={error.departureDate !== ''}
                        helpertext={error.departureDate !== '' ? error.departureDate : undefined}
                        sx={{ width: '100%' }}
                        slotProps={{ textField: { size: 'small' } }}
                      />
                    </div>
                    <div className='mt-5'>
                      <MobileDatePicker
                        required
                        id="arrivalDate"
                        name="arrivalDate"
                        format='YYYY-MM-DD'
                        label="Date d'arrivée *"
                        minDate={updatedAd.departureDate && dayjs(updatedAd.departureDate)}
                        value={dayjs(updatedAd.arrivalDate)}
                        onChange={(newValue) => { updateAdProp("arrivalDate", newValue); }}
                        error={error.arrivalDate !== ''}
                        helpertext={error.arrivalDate !== '' ? error.arrivalDate : undefined}
                        sx={{ width: '100%' }}
                        slotProps={{ textField: { size: 'small' } }}
                      />
                    </div>
                    <div className='mt-5'>
                      <MobileDateTimePicker
                        required
                        id="depositDeadline"
                        name="depositDeadline"
                        label="Délai de dépôt au lieu de dépôt *"
                        format='YYYY-MM-DD HH:mm'
                        ampm={false}
                        minTime={dayjs('00:00', 'HH:mm')}
                        maxTime={dayjs('23:59', 'HH:mm')}
                        minDate={dayjs()}
                        maxDate={updatedAd.departureDate && dayjs(updatedAd.departureDate)}
                        value={dayjs(updatedAd.depositDeadline)}
                        onChange={(newValue) => { updateAdProp("depositDeadline", dayjs(newValue).format("YYYY-MM-DD HH:mm")); }}
                        error={error.depositDeadline !== ''}
                        helpertext={error.depositDeadline !== '' ? error.depositDeadline : undefined}
                        sx={{ width: '100%' }}
                        slotProps={{ textField: { size: 'small' } }}
                      />
                    </div>
                  </Grid>
                }
                {updatedAd.type === 'REQUEST' &&
                  <Grid item xs={8}>
                    <div className='mt-2'>
                      <MobileDatePicker
                        required
                        id="minDate"
                        name="minDate"
                        label="Date minimum *"
                        format='YYYY-MM-DD'
                        disablePast
                        maxDate={updatedAd.maxDate && dayjs(updatedAd.maxDate)}
                        value={dayjs(updatedAd.minDate)}
                        onChange={(newValue) => { updateAdProp("minDate", newValue); }}
                        error={error.minDate !== ''}
                        helpertext={error.minDate !== '' ? error.minDate : undefined}
                        sx={{ width: '100%' }}
                        slotProps={{ textField: { size: 'small' } }}
                      />
                    </div>
                    <div className='mt-5'>
                      <MobileDatePicker
                        required
                        id="maxDate"
                        name="maxDate"
                        label="Date maximum *"
                        format='YYYY-MM-DD'
                        minDate={updatedAd.minDate && dayjs(updatedAd.minDate)}
                        value={dayjs(updatedAd.maxDate)}
                        onChange={(newValue) => { updateAdProp("maxDate", newValue); }}
                        error={error.maxDate !== ''}
                        helperText={error.maxDate !== '' ? error.maxDate : undefined}
                        sx={{ width: '100%' }}
                        slotProps={{ textField: { size: 'small' } }}
                      />
                    </div>
                  </Grid>
                }
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid container item alignItems="center" spacing={1}>
                <Grid item xs={4}>
                  <FormLabel component="legend">Notes</FormLabel>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    id="note"
                    name="note"
                    rows={3}
                    helperText="Mentionnez des conditions ou un message pour l'acheteur"
                    value={updatedAd.note}
                    onChange={(e) => updateAdProp("note", e.target.value)}
                  />
                </Grid>
                {updatedAd.type === 'SALE' &&
                  [
                    <Grid item xs={4}>
                      <FormLabel component="legend">Escales</FormLabel>
                    </Grid>
                    ,
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        id="stopovers"
                        name="stopovers"
                        rows={3}
                        helperText="Nombre et lieu de(s) stop(s) (Ne sera affiché qu'au coordinateur)"
                        defaultValue={updatedAd.stopovers}
                        onChange={(e) => updateAdProp("stopovers", e.target.value)}
                      />
                    </Grid>
                  ]
                }
              </Grid>
              {/* <Grid item xs={12} sm={6}>
  <InputLabel id="note">Note</InputLabel>
    <TextareaAutosize
      className='textarea'
      minRows={3}
      aria-labelledby='note'
      placeholder="Notes (Mentionnez des conditions ou un message à l'acheteur)"
      defaultValue={updatedAd.note}
      onChange={(e) => updateAdProp("note", e.target.value)}
    />
</Grid>
<Grid item xs={12} sm={6}>
  
    <InputLabel id='stopovers'>Escales</InputLabel>
    <TextareaAutosize
      className='textarea'
      minRows={3}
      aria-labelledby='stopovers'
      placeholder="Nombre et lieu de stop(s) (Ne sera affiché qu'au coordinateur)"
      defaultValue={updatedAd.stopoversDetails}
      onChange={(e) => updateAdProp("stopoversDetails", e.target.value)}
    />
</Grid> */}
            </Grid>
          }
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleCloseUpdateAd}>Annuler</Button>
          <Button variant='contained' onClick={(e) => {
            e.preventDefault();
            handleSubmit();
          }}>
            Enregistrer l'annonce
          </Button>
        </DialogActions>
      </Dialog>
      ,
      <CreateContentDialog setLoading={setLoading} openCreateContent={openCreateContent} setOpenCreateContent={setOpenCreateContent} />
    ]
  )
}

export default AdEditDialogUser
import { Avatar, IconButton, Menu, MenuItem, Stack, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { getFirstLetterUpperCase } from '../../../utils/stringFunction';
import Notifications from '../../user_components/Notifications/Notifications';

const MenuUser = ({ setLoading, user }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down('md'));

  const [anchorEl, setAnchorEl] = useState(null);
  const openUserMenu = Boolean(anchorEl);

  const handleUserClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseUserMenu = (event) => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('jwt');
    window.location.href = "/";
  };

  return (
    <Stack direction='row' sx={{ ml: 'auto', mr: 1, alignItems: 'center' }}>
      {/* <Chip icon={<ArrowDropDown />} label="Username" color="primary"
        onClick={handleUserClick}
      /> */}

      <Notifications setLoading={setLoading} user={user} />

      <IconButton onClick={handleUserClick}>
        <Avatar
          sx={{
            bgcolor: 'var(--primary)',
            height: isMatch ? '35px' : '45px',
            width: isMatch ? '35px' : '45px',
            fontSize: isMatch ? '14px' : '20px',
            fontWeight: '700'
          }}
        >
          {getFirstLetterUpperCase(user.firstName)}
          {getFirstLetterUpperCase(user.lastName)}
        </Avatar>
      </IconButton>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={openUserMenu}
        onClose={handleCloseUserMenu}
        MenuListProps={{
          'aria-label': 'basic-button',
        }}
      >
        {[
          <MenuItem key={1} onClick={() => {
            navigate('/user/profile');
            handleCloseUserMenu();
          }}>
            Compte
          </MenuItem>
        ]}
        {user && user.role === 'USER' &&
          [
            <MenuItem key={1} onClick={() => {
              navigate('/user/ads/published');
              handleCloseUserMenu();
            }}>
              Mes Annonces
            </MenuItem>
            ,
            <MenuItem key={2} onClick={() => {
              navigate('/user/ads/responses');
              handleCloseUserMenu();
            }}>
              Mes Réponses
            </MenuItem>

          ]
        }
        {user && (user.role === 'COORDINATOR' || user.role === 'ADMIN') &&
          [
            <MenuItem key={3} onClick={() => {
              navigate('/dashboard');
              handleCloseUserMenu();
            }}>
              Tableau de bord
            </MenuItem>
          ]
        }
        {[
          <MenuItem key={4} onClick={() => {
            handleLogout();
            handleCloseUserMenu();
          }}>
            Se déconnecter
          </MenuItem>
        ]}
      </Menu>
    </Stack>
  )
}

export default MenuUser
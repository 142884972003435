import React, { useEffect, useRef, useState } from 'react';
import {
    TextField, Button, Grid, Typography, IconButton, CssBaseline, Table, TableHead,
    TableRow, TableCell, TableBody, Box, Card, CardHeader, Divider, CardContent,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { deleteOpeningTime, getOpeningTimesByRelay } from '../../../services/openingTimeService'
import { getRelayById, updateRelay } from '../../../services/relayService';
import { days } from '../OpeningTimeCrud/days';
import OpeningTimeDialog from '../OpeningTimeCrud/OpeningTimeCreateDialog';
import OpeningTimeEditDialog from '../OpeningTimeCrud/OpeningTimeEditDialog';
import { useResponsiveMatch } from '../../../utils/useResponsiveMatch';
import { MainDrawer, DrawerHeader } from '../../public_components/Layouts/Drawer';
import NavBar from '../../public_components/Layouts/NavBar';
import PhoneNumberField from '../../public_components/Layouts/PhoneNumberField';
import DeleteDialog from '../../public_components/Layouts/DeleteDialog';
import { useJwt } from '../../../utils/UserProvider';
import showAlert from '../../../utils/ShowAlert';

const RelayView = ({ setLoading }) => {
    const { jwt } = useJwt();

    const relayId = window.location.href.split("/relays/")[1];
    // const url = '/relays/' + relayId;
    const isMatch = useResponsiveMatch();
    const [open, setOpen] = useState(false);

    const [relay, setRelay] = useState(null)
    const [openingTime, setOpeningTime] = useState({
        openingHour: "",
        closingHour: "",
        dayOfWeek: "",
        relay: null,
    })
    const [openingTimesByRelay, setOpeningTimesByRelay] = useState([]);
    const opTimeIdRef = useRef();
    const [openDeleteOpTime, setOpenDeleteOpTime] = useState(false);

    const updateRelayFields = (prop, value) => {
        setRelay(prevRelay => ({
            ...prevRelay,
            [prop]: value
        }));
    }

    useEffect(() => {
        const fetchRelay = async () => {
            setLoading(true);
            const relayData = await getRelayById(relayId, jwt);
            if (relayData !== null) {
                setRelay({ ...relayData });
            }
            setLoading(false);
        };
        const fetchOpeningTimes = async () => {
            setLoading(true);
            const openingTimesData = await getOpeningTimesByRelay(relayId, jwt);
            if (openingTimesData !== null) {
                setOpeningTimesByRelay([...openingTimesData] || []);
            }
            setLoading(false);
        };

        if (jwt) {
            fetchRelay();
            fetchOpeningTimes();
        }

    }, [jwt, relayId, setLoading]);

    const [openAddOpTime, setOpenAddOpTime] = useState(false);
    const handleClickAddOpTime = () => {
        setOpenAddOpTime(true);
    };

    const [openUpdateOpTime, setOpenUpdateOpTime] = useState(false);
    const handleClickUpdateOpTime = (id) => {
        opTimeIdRef.current = id;
        setOpenUpdateOpTime(true);
    };

    const handleSubmit = async () => {
        setLoading(true);
        const data = await updateRelay(relay, jwt)
        if (data !== null) {
            setRelay({ ...data });
            showAlert("Relai modifié avec succès !", 'success');
        }
        setLoading(false);
    };
    const handleReset = async (e) => {
        setLoading(true);
        const relayData = await getRelayById(relayId, jwt);
        if (relayData !== null) {
            setRelay({ ...relayData });
        }
        setLoading(false);
    };

    const handleClickDeleteOpTime = (id) => {
        opTimeIdRef.current = id;
        setOpenDeleteOpTime(true);
    }

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline key='baseline' />
            <NavBar setLoading={setLoading} key='navbar' open={open} setOpen={setOpen} />
            <MainDrawer setLoading={setLoading} key='mainDrawer' open={open} setOpen={setOpen} />
            <Box key='boxTwo' component="main" sx={{ flexGrow: 1, p: 3, mt: 3 }}>
                <DrawerHeader />
                <Card
                    sx={{
                        borderRadius: 3,
                        px: 2,
                        py: 2,
                        marginBottom: 2,
                        width: '100%',
                        minHeight: '400px',
                        ...(isMatch && {
                            marginTop: 8,
                        })
                    }}
                >
                    <CardHeader title="Détails du Point de relai"
                        sx={{
                            marginBottom: 2,
                        }}
                    />
                    <Divider />
                    <CardContent>
                        {relay &&
                            <Grid container spacing={2} direction={isMatch ? 'column' : 'row'}>
                                <Grid item container spacing={1} xs={12} sm={6}>
                                    <form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            handleSubmit();
                                        }}
                                        onReset={(e) => {
                                            e.preventDefault();
                                            handleReset();
                                        }}
                                        style={{ width: '100%' }}
                                    >
                                        <Grid item xs={12}>
                                            <Typography sx={{ my: 2 }} variant="h5" component="div">
                                                Point de relai
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={10}>
                                            <TextField
                                                required
                                                fullWidth
                                                label="Pays"
                                                name='country'
                                                value={relay.country}
                                                onChange={(e) => updateRelayFields("country", e.target.value)}
                                                sx={{ marginY: 1 }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={10}>
                                            <TextField
                                                required
                                                fullWidth
                                                label="Ville"
                                                name='city'
                                                value={relay.city}
                                                onChange={(e) => updateRelayFields("city", e.target.value)}
                                                sx={{ marginY: 1 }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={10}>
                                            <div className='my-1'>
                                                <PhoneNumberField
                                                    phone={relay.phone}
                                                    update={updateRelayFields}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={10}>
                                            <TextField
                                                required
                                                fullWidth
                                                label="Adresse email"
                                                name='email'
                                                type='email'
                                                value={relay.email}
                                                onChange={(e) => updateRelayFields("email", e.target.value)}
                                                sx={{ marginY: 1 }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={10}>
                                            <TextField
                                                required
                                                fullWidth
                                                label="Adresse"
                                                name='address'
                                                value={relay.address}
                                                onChange={(e) => updateRelayFields("address", e.target.value)}
                                                sx={{ marginY: 1 }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={10}>
                                            <div className='mt-8 mb-5 flex flex-row justify-center items-center space-x-5'>
                                                <Button variant="contained" type='submit' className='w-1/4'>
                                                    Modifier
                                                </Button>
                                                <Button variant="outlined" type='reset' className='w-1/4'>
                                                    Annuler
                                                </Button>
                                            </div>
                                        </Grid>
                                    </form>
                                </Grid>
                                <Grid item container spacing={2} xs={12} sm={6}>
                                    <Grid item xs={12}>
                                        <Typography sx={{ my: 2 }} variant="h5" component="div">
                                            Horaires d'ouverture
                                        </Typography>
                                        <Button variant="outlined"
                                            onClick={handleClickAddOpTime}
                                            sx={{ margin: 1 }}
                                        >
                                            Ajouter une horaire
                                        </Button>
                                        <Table sx={{ maxWidth: 550 }} aria-label="List of opening Times to save">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Jour</TableCell>
                                                    <TableCell align="right">Ouverture</TableCell>
                                                    <TableCell align="right">Fermeture</TableCell>
                                                    <TableCell align="right">Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {openingTimesByRelay?.map((opTime) => {
                                                    // Find the day object corresponding to opTime.dayOfWeek
                                                    const day = days.find(day => day.value === opTime.dayOfWeek);
                                                    // Render the label if day is found
                                                    const dayLabel = day ? day.label : '';
                                                    return (
                                                        <TableRow
                                                            key={opTime.id}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row" >
                                                                {dayLabel}
                                                            </TableCell>
                                                            <TableCell align="right">{opTime.openingHour && opTime.openingHour.slice(0, 5)}</TableCell>
                                                            <TableCell align="right">{opTime.closingHour && opTime.closingHour.slice(0, 5)}</TableCell>
                                                            <TableCell align="right">
                                                                <IconButton edge="end" aria-label="Modifier"
                                                                    onClick={(event) => {
                                                                        event.preventDefault();
                                                                        handleClickUpdateOpTime(opTime.id);
                                                                    }}
                                                                >
                                                                    <Edit />
                                                                </IconButton>
                                                                <IconButton edge="end" aria-label="Supprimer" color='error'
                                                                    onClick={(event) => {
                                                                        event.preventDefault();
                                                                        handleClickDeleteOpTime(opTime.id);
                                                                    }}
                                                                >
                                                                    <Delete />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }

                    </CardContent>
                </Card>
            </Box>
            {/* Add Opening Times Dialog  */}
            <OpeningTimeDialog setLoading={setLoading} relay={relay} openingTime={openingTime} setOpeningTime={setOpeningTime}
                openAddOpTime={openAddOpTime} setOpenAddOpTime={setOpenAddOpTime} setOpTimes={setOpeningTimesByRelay}
            />
            {opTimeIdRef.current &&
                [
                    <OpeningTimeEditDialog setLoading={setLoading} opTimeId={opTimeIdRef.current} openingTime={openingTime} setOpeningTime={setOpeningTime}
                        openUpdateOpTime={openUpdateOpTime} setOpenUpdateOpTime={setOpenUpdateOpTime} setOpTimes={setOpeningTimesByRelay}
                    />
                    ,
                    <DeleteDialog setLoading={setLoading} open={openDeleteOpTime} setOpen={setOpenDeleteOpTime} id={opTimeIdRef.current} deleteMethod={deleteOpeningTime} setData={setOpeningTimesByRelay} />
                ]
            }

        </Box>
    );
};

export default RelayView;

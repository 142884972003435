import { Avatar, Button, Card, CardActions, CardContent, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import { useResponsiveMatch } from '../../../utils/useResponsiveMatch'
import { capitalizeEachWord, getFirstLetterUpperCase, reassambleName } from '../../../utils/stringFunction'


const UserInfo = ({ user, editable, setEditable, handleReset }) => {

    const isMatch = useResponsiveMatch();
    const handleClickEditable = () => {
        if (editable) {
            setEditable(false);
            handleReset();
        }
        else {
            setEditable(true);
        }
    }
    const firstNameData = user.firstName;
    const lastNameData = user.lastName;

    return (
        <Card
            sx={{
                borderRadius: 3,
                px: 6,
                py: 2,
                marginLeft: 2,
                marginBottom: 2,
                ...(isMatch && {
                    marginTop: 8,
                })
            }}
        >
            <CardContent>
                <Stack spacing={2} sx={{ alignItems: 'center' }}>
                    <div>
                        <Avatar sx={{ bgcolor: 'var(--primary)', height: '80px', width: '80px' }}>
                            {getFirstLetterUpperCase(firstNameData)}
                            {getFirstLetterUpperCase(lastNameData)}
                        </Avatar>
                    </div>
                    <Stack spacing={1} sx={{ textAlign: 'center' }}>
                        <Typography variant="h5">
                            {capitalizeEachWord(reassambleName(firstNameData, lastNameData))}
                        </Typography>
                        <Typography color="text.secondary" variant="body2">
                            {user.username}
                        </Typography>
                    </Stack>
                </Stack>
            </CardContent>
            <Divider />
            <CardActions>
                <Button fullWidth variant="text" onClick={handleClickEditable}>
                    {editable ? (
                        <>
                            Voir mes informations
                        </>
                    )
                        :
                        (
                            <>
                                Editer mes informations
                            </>
                        )
                    }
                </Button>
            </CardActions>
        </Card>
    )
}

export default UserInfo
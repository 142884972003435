import React, { useEffect, useState } from 'react'
import {
    Button, CssBaseline, Card, CardActions, CardContent, CardHeader, CardMedia,
    Box, Divider, Grid, Typography
} from '@mui/material';
import Footer from '../Layouts/Footer';
import { CalendarToday, DateRange, Done, Event, Place, Sms } from '@mui/icons-material';
import { getAdById } from '../../../services/adService';
import OfferCreateDialog from '../../user_components/Offers/OfferCreateDialog';
import { getOffersByAd } from '../../../services/offerService';
import { getAuthenticatedUser } from '../../../services/userService';
import NavBar from '../Layouts/NavBar';
import { MainDrawer, DrawerHeader } from '../Layouts/Drawer';
import { useRole } from '../../../utils/UserProvider';
import dayjs from 'dayjs';
import { useJwt } from '../../../utils/UserProvider';


const AdDetails = ({ setLoading }) => {
    const { jwt } = useJwt();

    const adId = window.location.href.split("/ads/")[1];
    // const url = '/ads/' + adId;
    const [open, setOpen] = useState(false);
    const { role } = useRole();

    const [responded, setResponded] = useState(false);
    const [user, setUser] = useState(null);
    const [ad, setAd] = useState({
        title: '',
        type: '',
        picture: '',
        weight: 23,
        price: 1100,
        depositDeadline: '',
        departureDate: '',
        arrivalDate: '',
        note: '',
        stopoversDetails: '',
        departureRelay: null,
        arrivalRelay: null,
    });

    const [openDialog, setOpenDialog] = useState(false);
    const handleClickAnswerButton = () => {
        setOpenDialog(true);
    };

    useEffect(() => {
        const fetchAd = async () => {
            setLoading(true);
            const adData = await getAdById(adId, jwt);
            if (adData !== null) {
                setAd({ ...adData });
            }
            setLoading(false);
        };
        if (jwt) {
            fetchAd();
        }
    }, [adId, jwt, setLoading]);

    useEffect(() => {
        const fetchUser = async () => {
            setLoading(true);
            const userData = await getAuthenticatedUser(jwt);
            if (userData !== null) {
                setUser(userData);
            }
            setLoading(false);
        };
        if (jwt) {
            fetchUser();
        }
    }, [jwt, setLoading]);

    useEffect(() => {
        const fetchAdOffers = async () => {
            setLoading(true);
            const responseData = await getOffersByAd(adId, jwt);
            if (responseData !== null && user !== null) {
                if (responseData.find((offer) => offer.bidder?.id === user.id)) {
                    setResponded(true);
                }
            }
            setLoading(false);
        };
        if (jwt) {
            fetchAdOffers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jwt, openDialog, user, setLoading]);

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <NavBar setLoading={setLoading} open={open} setOpen={setOpen} />
            {(role === 'ADMIN' || role === 'COORDINATOR') && <MainDrawer setLoading={setLoading} open={open} setOpen={setOpen} />}
            <Box sx={{ flexGrow: 1 }}>
                <Box component="main" sx={{ p: 3, mt: 3 }}>
                    <DrawerHeader />
                    <div className="my-5 pt-5">
                        <Grid item container xs={12}>
                            <Grid item xs={12}>
                                <nav aria-label="Breadcrumb">
                                    <ol className="mx-auto flex max-w-2xl items-center space-x-2 pb-4 md:pb-2 px-5 lg:max-w-7xl">
                                        <li key={1}>
                                            <div className="flex items-center">
                                                <a href='/ads' className="mr-2">
                                                    Annonces
                                                </a>
                                                <span className='text-gray-400'>{`>`}</span>
                                            </div>
                                        </li>
                                        <li key={2}>
                                            <div className="flex items-center">
                                                <span className="text-gray-500 hover:text-gray-600">
                                                    {ad.title}
                                                </span>
                                            </div>
                                        </li>
                                    </ol>
                                </nav>
                                <Typography variant='h5' className="px-5 pt-4" >
                                    Détails de l'annonce
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={3.5} order={{ xs: 2, md: 3 }}>
                                <Card
                                    sx={{
                                        borderRadius: 3,
                                        m: 2,
                                    }}
                                >
                                    {ad.picture && (
                                        <CardMedia
                                            sx={{ height: 140 }}
                                            image={ad.picture}
                                            title={ad.title}
                                        />
                                    )}
                                    <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'center' }} >
                                        <div className='mx-3'>
                                            <CalendarToday color='primary' sx={{ height: 20 }} />
                                            <span className='text-sm'>Publiée le {ad.publicationDate && dayjs(ad.publicationDate).format('YYYY-MM-DD HH:MM')}</span>
                                        </div>
                                        <div className='mx-3'>
                                            <Sms color='primary' sx={{ height: 20 }} />
                                            <span className='text-sm'>{ad.offersNumber} réponse(s)</span>
                                        </div>
                                    </CardContent>
                                    {user && ad.publisher && user.id !== ad.publisher.id &&
                                        <CardActions sx={{ justifyContent: 'flex-start', mb: 4, mx: 3 }}>
                                            {!responded ? (
                                                <Button
                                                    size='small'
                                                    variant='contained'
                                                    onClick={handleClickAnswerButton}
                                                >
                                                    Répondre à l'annonce
                                                </Button>
                                            ) : (
                                                <Button
                                                    size='small'
                                                    variant='outlined'
                                                    endIcon={<Done />}
                                                    sx={{ cursor: 'default' }}
                                                >
                                                    Annonce Répondue
                                                </Button>
                                            )
                                            }
                                        </CardActions>
                                    }
                                </Card >
                            </Grid>
                            <Grid item xs={12} md={8.5} order={{ xs: 3, md: 2 }}>
                                <Card
                                    sx={{
                                        borderRadius: 3,
                                        px: 4,
                                        py: 2,
                                        m: 2,
                                    }}
                                >
                                    <CardHeader title={ad.title} />
                                    <Divider />
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} display='flex' flexDirection='column' gap={2}>
                                                <Typography variant='body2' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    Prix <span style={{ color: 'var(--primary)' }} className='font-semibold'>
                                                        {ad.price} MAD
                                                    </span>
                                                </Typography>
                                                <Typography variant='body2' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    Poids <span style={{ color: 'var(--primary)' }} className='font-semibold'>
                                                        {ad.weight} KG
                                                    </span>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider orientation="horizontal" flexItem />
                                            </Grid>
                                            <Grid item xs={12} display='flex' flexDirection='column' gap={2}>
                                                <Typography variant='h6' component='span'>
                                                    Départ - Arrivée
                                                </Typography>
                                                <Typography variant='body2' component='span' sx={{ display: 'div', gap: 1, alignItems: 'center' }}>
                                                    <p>
                                                        <Place sx={{ height: 20 }} color='primary' />
                                                        De
                                                        <span className='font-semibold'>
                                                            { } {ad.departureRelay && ad.departureRelay.address} {ad.departureRelay && ad.departureRelay.city}, {ad.departureRelay && ad.departureRelay.country}
                                                        </span>
                                                    </p>
                                                    <p className='ml-6'>
                                                        à
                                                        <span className='font-semibold'>
                                                            { } {ad.arrivalRelay && ad.arrivalRelay.address} {ad.arrivalRelay && ad.arrivalRelay.city}, {ad.arrivalRelay && ad.arrivalRelay.country}
                                                        </span>
                                                    </p>
                                                </Typography>
                                                {ad.type === 'REQUEST' &&
                                                    <Typography variant='body2' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                        <DateRange sx={{ height: 20 }} color='primary' />
                                                        Entre le
                                                        <span className='font-semibold'>{ad.minDate}</span>
                                                        et le
                                                        <span className='font-semibold'>{ad.maxDate}</span>
                                                    </Typography>
                                                }
                                                {ad.type === 'SALE' &&
                                                    <>
                                                        <Typography variant='body2' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                            <CalendarToday sx={{ height: 20 }} color='primary' />
                                                            Départ le
                                                            <span className='font-semibold'>{ad.departureDate}</span>
                                                            et arrivée le
                                                            <span className='font-semibold'>{ad.arrivalDate}</span>
                                                        </Typography>
                                                        <Typography variant='body2' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                            <Event sx={{ height: 20 }} color='primary' />
                                                            Délai de dépot au relai
                                                            <span className='font-semibold'>{ad.depositDeadline}</span>
                                                        </Typography>

                                                    </>
                                                }
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Divider orientation="horizontal" flexItem />
                                            </Grid>
                                            <Grid item xs={12} display='flex' flexDirection='column' gap={1}>
                                                <Typography variant='h6' component='span'>
                                                    Bagages
                                                </Typography>
                                                {ad.type === 'REQUEST' ?
                                                    <>
                                                        <Typography variant='body2' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                            Nombre
                                                            <span className='font-semibold'>{ad.luggageNumber}</span>
                                                        </Typography>
                                                        <Typography variant='body2' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                            Description
                                                            <span className='font-semibold'>{ad.luggageDescription}</span>
                                                        </Typography>
                                                        <Typography variant='body2' component='span' display='flex' gap={1} alignItems='center'>
                                                            Contenu des bagages
                                                            {ad.adContents && ad.adContents.map((content) =>
                                                                <span className='font-semibold' key={content.id}>
                                                                    {content.designation}
                                                                    {ad.adContents[ad.adContents.length - 1].id !== content.id && `, `}
                                                                </span>
                                                            )}
                                                        </Typography>
                                                    </>
                                                    :
                                                    <Typography variant='body2' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                        Nombre maximum de bagages
                                                        <span className='font-semibold'>{ad.luggageNumber}</span>
                                                    </Typography>
                                                }
                                            </Grid>

                                            {ad.note &&
                                                <>
                                                    <Grid item xs={12}>
                                                        <Divider orientation="horizontal" />
                                                    </Grid>
                                                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }} >
                                                        <Typography variant='body1' component='span'>Note</Typography>
                                                        <Typography variant='body2' component='span' color='GrayText'>{ad.note}</Typography>
                                                    </Grid>
                                                </>
                                            }
                                        </Grid>
                                    </CardContent>
                                </Card >
                            </Grid>
                        </Grid>
                        <OfferCreateDialog setLoading={setLoading} ad={ad} openCreateOffer={openDialog} setOpenCreateOffer={setOpenDialog} setAd={setAd} />
                    </div >
                </Box>
                <Footer />
            </Box>
        </Box>
    );
}

export default AdDetails

import React from 'react'
import './preloader.css'
import { CircularProgress } from '@mui/material';

const Preloader = ({ show }) => {
    return (
        <div className={`loading-indicator ${show ? 'show' : ''}`}>
            <CircularProgress color="primary" variant="indeterminate" />
        </div>
    )
}

export default Preloader
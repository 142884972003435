import React, { useState } from 'react'
import Header from '../Home/Header'
import HomeSectionCarousel from '../Home/HomeSectionCarousel'
import Testimonials from '../Home/Testimonials'
import Footer from '../Layouts/Footer'
import { Divider, CssBaseline, Box } from '@mui/material'
import { useRole } from '../../../utils/UserProvider'
import NavBar from '../Layouts/NavBar'
import { MainDrawer, DrawerHeader } from '../Layouts/Drawer';

const HomePage = ({ setLoading }) => {
  const [open, setOpen] = useState(false);
  const { role } = useRole();

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <NavBar setLoading={setLoading} open={open} setOpen={setOpen} />
      {(role === 'ADMIN' || role === 'COORDINATOR') &&
        <MainDrawer setLoading={setLoading} open={open} setOpen={setOpen} />
      }
      <Box sx={{ flexGrow: 1 }}>
        <Box component="main" sx={{ p: 3, mt: 3 }}>
          <DrawerHeader />
          <Header setLoading={setLoading} />
          <Divider sx={{ marginY: 5 }} />
          <HomeSectionCarousel setLoading={setLoading} />
          <Divider sx={{ marginY: 5 }} />
          <Testimonials />
        </Box>
        <Footer />
      </Box>
    </Box>
  );
}

export default HomePage
import { Button, Card, CardActions, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Stack, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { deleteUser, getAuthenticatedUser, verifyUserPasswordById, updateUserCredentials } from '../../../services/userService';
import { WarningAmber } from '@mui/icons-material';
import PasswordInput from '../../public_components/Layouts/PasswordInput';
import { useJwt } from '../../../utils/UserProvider';
import showAlert from '../../../utils/ShowAlert';

const UserAccount = ({ setLoading, user, setUser }) => {

  const { jwt } = useJwt();
  const url = '/';

  const initialRequest = {
    username: '',
    email: '',
    password: '',
    newPassword: '',
    confirmNewPassword: '',
  };
  const [userRequest, setUserRequest] = useState(initialRequest);
  const [error, setError] = useState(initialRequest);
  const [updatePassword, setUpdatePassword] = useState(false);
  const [verifiedPassword, setVerifiedPassword] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);


  const handleClickUpdatePassword = () => {
    if (updatePassword) {
      setUpdatePassword(false);
    }
    else {
      setUpdatePassword(true);
    }
  }

  const verifyActualPassword = async () => {
    setLoading(true);
    const samePassword = await verifyUserPasswordById(user.id, userRequest, jwt);
    if (samePassword !== null) {
      if (samePassword) {
        setVerifiedPassword(true);
      }
      else {
        setVerifiedPassword(false);
        showAlert("Mot de passe incorrect !", 'error');
      }
    }
    setLoading(false);
  }

  const onInputChange = e => {
    const { name, value } = e.target;
    setUserRequest(prev => ({
      ...prev,
      [name]: value
    }));
    // validateInput(e);
  }

  // const validateInput = e => {
  //   let { name, value } = e.target;
  //   setError(prev => {
  //     const stateObj = { ...prev, [name]: "" };

  //     switch (name) {
  //       case "newPassword":
  //         if (!value) {
  //           stateObj[name] = "Veuillez entrer le mot de passe.";
  //         } else if (userRequest.confirmNewPassword && value !== userRequest.confirmNewPassword) {
  //           stateObj["confirmNewPassword"] = "Veuillez saisir le même mot de passe.";
  //         } else {
  //           stateObj["confirmNewPassword"] = userRequest.confirmNewPassword ? "" : error.confirmNewPassword;
  //         }
  //         break;

  //       case "confirmNewPassword":
  //         if (!value) {
  //           stateObj[name] = "Veuillez à nouveau entrer le mot de passe.";
  //         } else if (userRequest.newPassword && value !== userRequest.newPassword) {
  //           stateObj[name] = "Veuillez saisir le même mot de passe.";
  //         }
  //         break;

  //       default:
  //         break;
  //     }

  //     return stateObj;
  //   });
  // }

  const handleSubmit = async () => {
    setLoading(true);
    if (userRequest.confirmNewPassword !== userRequest.newPassword) {
      showAlert("Les mots de passe ne sont pas identiques!", 'error')
    }
    else {
      const request = {
        ...user,
        username: userRequest.username !== '' ? userRequest.username : user.username,
        email: userRequest.email !== '' ? userRequest.email : user.email,
        password: userRequest.newPassword,
      };
      console.log("user request " + request);
      const savedUser = await updateUserCredentials(request, jwt);
      console.log(savedUser)
      if (savedUser !== null) {
        setUser({ ...savedUser });
        localStorage.removeItem('jwt');
        window.location.href = url;
      }
    }
    setLoading(false);
  };

  const handleReset = async () => {
    try {
      setLoading(true);
      const userData = await getAuthenticatedUser(jwt);
      setUser(userData);
      // console.log(user);
      setUpdatePassword(false);
      setLoading(false);
    }
    catch (error) {
      console.error("Error fetching user", error);
    }
    finally {
      setLoading(false);
    }
    setLoading(false);
  };


  const handleOpenDeleteDialog = () => setOpenDeleteDialog(true);
  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  const handleDeleteAccount = async () => {
    try {
      setLoading(true);
      console.log("id", user.id);
      const response = await deleteUser(user.id, jwt);
      console.log(response);
      // handleCloseDeleteDialog(false);
      // window.location.href = url;
      setLoading(false);
    }
    catch (error) {
      console.error("Error deleting user", error);
    }
    finally {
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <>
      <Card
        sx={{
          borderRadius: 3,
          px: 6,
          py: 2,
          marginLeft: 2,
          marginBottom: 2,
        }}
        component='form'
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        onReset={(e) => {
          e.preventDefault();
          handleReset();
        }}
      >
        <CardHeader title="Informations d'identification" />
        <Divider />
        <CardContent>
          <Stack spacing={3} sx={{ maxWidth: 'sm' }}>
            <TextField
              fullWidth
              name="username"
              label="Nom d'utilisateur"
              id="username"
              defaultValue={user.username}
              onChange={onInputChange}
              error={error.username !== ''}
              helperText={error.username !== '' && error.username}
            />
            <TextField
              disabled
              fullWidth
              name="email"
              label="Adresse email"
              type="email"
              id="email"
              defaultValue={user.email}
              onChange={onInputChange}
              error={error.email !== ''}
              helperText={error.email !== '' && error.email}
            />
            {!updatePassword ? (
              <Button
                variant='outlined' sx={{ p: 1 }}
                onClick={handleClickUpdatePassword}
              >
                Changer le mot de passe
              </Button>

            ) : (
              !verifiedPassword ?
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={9} >
                    <PasswordInput
                      password={userRequest.password}
                      handleChange={onInputChange}
                      name={"password"}
                      label={"Mot de passe actuel"}
                      error={error}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} >
                    <Button
                      variant='outlined'
                      onClick={verifyActualPassword}
                      sx={{ p: 2 }}
                    >
                      Valider
                    </Button>
                  </Grid>
                </Grid>
                :
                <>
                  {error.email && <Typography variant='body2' color='red'>{error.email}</Typography>}
                  <PasswordInput
                    password={userRequest.newPassword}
                    handleChange={onInputChange}
                    name={"newPassword"}
                    label={"Nouveau mot de passe"}
                    error={error}
                  />
                  <PasswordInput
                    password={userRequest.confirmNewPassword}
                    handleChange={onInputChange}
                    name={"confirmNewPassword"}
                    label={"Confirmer le nouveau mot de passe"}
                    error={error}
                  />
                </>
            )}
          </Stack>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            // disabled={userRequest.confirmNewPassword !== '' && error.confirmNewPassword !== ''}
            type='submit'
          >
            Enregistrer
          </Button>
          <Button
            variant="outlined"
            type='reset'
          >
            Annuler
          </Button>
        </CardActions>
      </Card>

      <Card
        sx={{
          borderRadius: 3,
          px: 6,
          py: 2,
          marginLeft: 2,
          marginBottom: 2,
        }}
        component='form'
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <CardHeader title="Supprimer mon compte" />
        <Divider />
        <CardContent>
          <Button variant='outlined' color="error" onClick={handleOpenDeleteDialog} >
            Désactiver mon compte
          </Button>
        </CardContent>
      </Card >

      {/* <Modal
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="modal-delete-account-label"
        aria-describedby="modal-delete-account-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50sv',
            borderRadius: 3,
            boxShadow: 24,
            p: 3
          }}
          component='form'
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <WarningAmber color='error' sx={{ width: '35px', height: '35px' }} />
          <CardHeader title="Confirmer suppression de compte" />
          <Divider />
          <CardContent>
            <Typography color='GrayText' variant='body1' >
              Êtes-vous sûr de vouloir désactiver votre compte ? Toutes vos données seront définitivement supprimées. Cette action ne peut être annulée.
            </Typography>
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: 'flex-end' }}>
          </CardActions>
        </Box >
      </Modal> */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        component='form'
        onSubmit={(e) => {
          e.preventDefault();
          handleDeleteAccount();
        }}

      >
        <DialogTitle id="alert-dialog-title" sx={{ alignItems: 'center' }}>
          <WarningAmber color='error' sx={{ width: '35px', height: '35px', mr: 2 }} />
          Confirmer suppression de compte
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Êtes-vous sûr de vouloir désactiver votre compte ?
            {/* <br/>
            Toutes vos données seront définitivement supprimées.
            <br/>
            Cette action est irreversible. */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color='inherit' onClick={handleCloseDeleteDialog}>Annuler</Button>
          <Button color='error' type='submit' autoFocus>
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>

    </>
  )
}

export default UserAccount
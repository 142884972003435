export const status_array = [
    { value: "PENDING_RESPONSE", label: "En attente" },
    { value: "ACCEPTED", label: "Validée" },
    { value: "REFUSED", label: "Refusé" },
    { value: "CANCELLED", label: "Annulée" },
  ];

export function statusLabel(status) {
    const statusObject = status_array.find(
      (s) => s.value === status
    );
    const status_label = statusObject ? statusObject.label : '';
    return status_label;
  }
  
  export function statusColor(status) {
    let status_color;
    switch (status) {
      case status_array.at(0).value:
        status_color = "primary";
        break;
      case status_array.at(1).value:
        status_color = "success";
        break;
      case status_array.at(2).value:
        status_color = "error";
        break;
      case status_array.at(3).value:
        status_color = "default";
        break;
      default:
        status_color = "default";
        break;
    }
    return status_color;
  }
import React from 'react'

const Footer = ({ footerRef }) => {
  return (
    <div ref={footerRef} className="bottom-0 w-full flex flex-col items-center justify-center 
      p-2 mt-10 bg-black text-white text-center"
    >
      <div>
        <img alt='SendNous logo' className='m-auto h-30 w-40'
          src={process.env.PUBLIC_URL + "/logo.png"}
        />
      </div>
      <p className="font-bold">
        Économiser et gagner de l'argent avec la meilleure
        application pour l'échange de kilos de bagages d'avion.
      </p>
      <p>Copyright © 2024 - Tous droits réservés</p>
    </div>
  )
}

export default Footer
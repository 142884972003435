import { Box, Button, Container, CssBaseline, IconButton, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Lock } from '@mui/icons-material';
import { forgotPassword } from '../../../services/userService';
import showAlert from '../../../utils/ShowAlert';

const ForgotPassword = ({setLoading}) => {
    const [credentials, setCredentials] = useState({
        email: '',
        phone: ''
    });
    const [error, setError] = useState({
        email: '',
        phone: ''
    });

    const updateError = (prop, value) => {
        setError(prev => ({
            ...error,
            [prop]: value
        }));
    }

    const updateCredentials = (prop, value) => {
        setCredentials(prev => ({
            ...credentials,
            [prop]: value
        }));
        if (value === '') {
            updateError(prop, "Ce champ est obligatoire")
        }
        else {
            updateError(prop, '');
        }
    }

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        const result = await forgotPassword(credentials);
        if (result !== null) {
            showAlert("Un email de réinitialisation du mot de passe a été envoyé", 'success');
        }
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);

        const timer = setTimeout(() => {
            setLoading(false);
        }, 200);

        return () => clearTimeout(timer);
    }, [setLoading]);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 7,
                    marginBottom: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    boxShadow: 3,
                    borderRadius: 3,
                    px: 6,
                    py: 6
                }}
            >
                <IconButton
                    size="large" sx={{ m: 1 }}
                    LinkComponent="a" href="/"
                >
                    <Lock sx={{ width: 100, height: 80, mr: 2, color: 'var(--primary)' }} />
                </IconButton>
                <Typography component="h1" variant="h5" sx={{ mb: 3 }}>
                    Mot de Passe Oublié ?
                </Typography>
                <form
                    onSubmit={handleSubmit}
                    noValidate
                >
                    <p>Réinitialiser votre mot de passe</p>
                    <TextField
                        required
                        fullWidth
                        margin="normal"
                        id="email"
                        label="Adresse email"
                        name="email"
                        onChange={(e) => updateCredentials('email', e.target.value)}
                        error={error.email !== ''}
                        helperText={error.email}
                    />
                    {/* Ou
                    <PhoneNumberField
                        phone={credentials.phone}
                        update={updateCredentials}
                    /> */}
                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Réinitialiser le mot de passe
                    </Button>
                    {/* <Grid container>
                        <Grid item xs>
                            <Link variant="body2" href="/sign-in">
                                Se connecter
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link variant="body2" href="/sign-up">
                                Créer un compte
                            </Link>
                        </Grid>
                    </Grid> */}
                </form>
            </Box>
        </Container >
    )
}

export default ForgotPassword
import {
  Avatar, Button, Chip, CardContent, Card, CardHeader,
  CssBaseline, Divider, Box,
} from '@mui/material';
import { Delete, Edit, Visibility } from '@mui/icons-material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { getAds } from '../../../services/adService';
import { getRelays } from '../../../services/relayService';
import { statusColor, statusLabel, typeLabel, status_array, adTypes } from '../../../data/adData';
import AdCreateDialog from '../../user_components/AdCrud/AdCreateDialog';
import SelectFilter from '../../user_components/SelectFilter/SelectFilter';
import AdEditDialog from './AdEditDialog';
import DeleteDialog from '../../public_components/Layouts/DeleteDialog';
import { deleteAd } from '../../../services/adService';
import dayjs from 'dayjs';
import { MainDrawer, DrawerHeader } from '../../public_components/Layouts/Drawer';
import NavBar from '../../public_components/Layouts/NavBar';
import { useNavigate } from 'react-router-dom';
import { useJwt } from '../../../utils/UserProvider';

const ManageAds = ({ setLoading }) => {
  const { jwt } = useJwt();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const [ads, setAds] = useState([]);
  const [relays, setRelays] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const adIdRef = useRef();

  const [openCreateAd, setOpenCreateAd] = useState(false);
  const [openUpdateAd, setOpenUpdateAd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);


  useEffect(() => {
    const fetchRelays = async () => {
      setLoading(true);
      const relaysData = await getRelays();
      if (relaysData !== null && relays.length !== Object.keys(relaysData).length)
        relaysData.forEach((relay) =>
          relays.push({ label: relay.city, value: relay })
        );
      setLoading(false);
    };

    fetchRelays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoading])

  useEffect(() => {
    const fetchAds = async () => {
      setLoading(true);
      const adsData = await getAds(jwt);
      if (adsData !== null)
        setAds(adsData);
      setLoading(false);
    };

    if (jwt) {
      fetchAds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt, setLoading])

  const handleClickCreateAd = () => {
    setOpenCreateAd(true);
  };

  const handleClickUpdateAd = (id) => {
    adIdRef.current = id;
    setOpenUpdateAd(true);
  };

  const handleClickDeleteAd = (id) => {
    adIdRef.current = id;
    setOpenDelete(true);
  };

  const typeFilter = {
    label: '=',
    value: 'type', // Assuming 'type' is the field in your data
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.value) return null;
      return ({ value }) => value === filterItem.value;
    },
    InputComponent: SelectFilter,
    InputComponentProps: {
      options: adTypes,
    },
  };

  const statusFilter = {
    label: '=',
    value: 'status', // Assuming 'status' is the field in your data
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.value) return null;
      return ({ value }) => value === filterItem.value;
    },
    InputComponent: SelectFilter,
    InputComponentProps: {
      options: status_array,
    },
  };

  const departureRelayFilter = {
    label: 'Ville',
    value: 'departureRelay',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.value) return null;
      return ({ value }) => value.id === filterItem.value.id;
    },
    InputComponent: SelectFilter,
    InputComponentProps: {
      options: relays,
    },
  };

  const arrivalRelayFilter = {
    label: 'Ville',
    value: 'departureRelay',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.value) return null;
      return ({ value }) => value.id === filterItem.value.id;
    },
    InputComponent: SelectFilter,
    InputComponentProps: {
      options: relays,
    },
  };

  const columns = useMemo(() => [
    {
      field: 'actions',
      headerName: "Actions",
      type: 'actions',
      width: 300,
      renderCell: (params) =>
        <Box
          sx={{
            m: 1,
            position: 'relative',
            display: 'flex',
            gap: '5px'
          }}
        >
          <Button
            variant="outlined"
            color='info'
            size='small'
            startIcon={<Visibility />}
            onClick={() => {
              navigate(`/user/ads/${params.id}`);
            }}
          >
            Détails
          </Button>
          {params.row.status &&
            (
              (params.row.status !== 'CLOSED' && params.row.status !== 'PUBLISHED')
              ||
              (
                params.row.status === 'PUBLISHED' &&
                params.row.customer === null &&
                params.row.offersNumber > 0
              )
            )
            &&
            <Button
              variant="outlined"
              size='small'
              startIcon={<Edit />}
              onClick={(e) => {
                e.preventDefault();
                handleClickUpdateAd(params.id);
              }}
              sx={{
                color: 'var(--primary)'
              }}
            >
              Editer
            </Button>
          }
          <Button
            variant="outlined"
            color='error'
            size='small'
            startIcon={<Delete />}
            onClick={(e) => {
              e.preventDefault();
              handleClickDeleteAd(params.id);
            }}
          >
            Supprimer
          </Button>
        </Box>
    },
    {
      field: 'id',
      headerName: "Id",
    },
    {
      field: 'publisher',
      headerName: "Annonceur",
      renderCell: (params) => (
        params.row.publisher?.username
      ),
    },
    {
      field: 'customer',
      headerName: "Client",
      renderCell: (params) => (
        params.row.customer?.username
      ),
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 130,
      filterOperators: [typeFilter],
      renderCell: (params) =>
        <Chip
          avatar={<Avatar src={params.row.picture} />}
          label={
            typeLabel(params.row.type)
          }
          variant="outlined"
        />,
    },
    {
      field: 'status',
      headerName: "Statut",
      width: 170,
      filterOperators: [statusFilter],
      renderCell: (params) =>
        <Chip
          label={
            statusLabel(params.row.status)
          }
          variant="outlined"
          color={statusColor(params.row.status)}
        />,
    },
    {
      field: 'offersNumber',
      headerName: "Offres",
      width: 70,
    },
    {
      field: 'title',
      headerName: "Titre",
      width: 200,
    },
    {
      field: 'creationDate',
      headerName: "Créée le",
      valueFormatter: ({ value }) => value ? dayjs(value).format('YYYY-MM-DD') : '',
    },
    {
      field: 'publicationDate',
      headerName: "Publiée le",
      valueFormatter: ({ value }) => value ? dayjs(value).format('YYYY-MM-DD HH:MM') : '',
      width: 150,
    },
    {
      field: 'departureRelay',
      headerName: 'Départ',
      width: 150,
      filterOperators: [departureRelayFilter],
      renderCell: (params) => params.row.departureRelay.city,
    },
    {
      field: 'arrivalRelay',
      headerName: 'Arrivée',
      width: 150,
      filterOperators: [arrivalRelayFilter],
      renderCell: (params) => params.row.arrivalRelay.city,
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <NavBar setLoading={setLoading} open={open} setOpen={setOpen} />
      <MainDrawer setLoading={setLoading} open={open} setOpen={setOpen} />
      <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 3, width: '100%' }}>
        <DrawerHeader />
        <Card
          sx={{
            borderRadius: 3,
            px: 2,
            py: 2,
            marginBottom: 2,
            width: '95%',
            minHeight: '400px',
          }}
        >
          <CardHeader title="Gestion des Annonces"
            sx={{
              marginBottom: 2,
            }}
          />
          <Divider />
          <CardContent>
            <Button
              variant="contained"
              onClick={() => {
                // table.setCreatingRow(true); //simplest way to open the create row modal with no default values
                handleClickCreateAd();
              }}
              sx={{ textAlign: 'center', my: 3, }}
            >
              Créer une annonce
            </Button>
            <DataGrid
              columns={columns}
              autoHeight
              rows={ads}
              initialState={{
                ...ads.initialState,
                pagination: { paginationModel: { pageSize: pageSize } },
                sorting: {
                  sortModel: [{ field: 'id', sort: 'desc' }],
                },
              }}
              pageSize={pageSize}
              pageSizeOptions={[5, 10, 25]}
              rowHeight={70}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  printOptions: { disableToolbarButton: true },
                  csvOptions: { disableToolbarButton: true },
                },
              }}
              sx={{
                // '--DataGrid-overlayHeight': '300px',
                border: 'none',
                width: '100%',
                mb: 5,
              }}
            />
          </CardContent>
        </Card>
      </Box>
      {openCreateAd &&
        <AdCreateDialog setLoading={setLoading} openCreateAd={openCreateAd} setOpenCreateAd={setOpenCreateAd} setAds={setAds} />
      }
      {adIdRef.current &&
        [
          <AdEditDialog setLoading={setLoading} adId={adIdRef.current} openUpdateAd={openUpdateAd} setOpenUpdateAd={setOpenUpdateAd} setAds={setAds} />
          ,
          <DeleteDialog setLoading={setLoading} open={openDelete} setOpen={setOpenDelete} id={adIdRef.current} deleteMethod={deleteAd} setData={setAds} />
        ]
      }
    </Box>
  );
}

export default ManageAds
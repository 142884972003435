import React, { useEffect, useRef, useState } from 'react'
import { styled, useTheme } from '@mui/material/styles';
import { Tab, Tabs, useMediaQuery, Button, AppBar as MuiAppBar, Toolbar, Box, Stack, IconButton, Avatar } from '@mui/material';
import { useLocation, Link } from 'react-router-dom';
import { PageDrawer } from './Drawer';
import MenuUser from './MenuUser';
import { useJwt } from '../../../utils/UserProvider';
import { getAuthenticatedUser } from '../../../services/userService';
import Authentication from '../../user_components/Auth/Authentication';
import Notifications from '../../user_components/Notifications/Notifications';


const AppBar = styled(MuiAppBar)(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
}));

const NavBar = ({ setLoading, open, setOpen }) => {
    const { jwt } = useJwt();
    const location = useLocation();
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down('md'));

    const pages = [
        { name: 'Accueil', href: '/' },
        { name: 'Annonces', href: '/ads' },
        { name: 'A Propos', href: '/about' },
        { name: 'Contact', href: '/contact' },
    ]
    const routeToTabIndex = {
        "/": 0,
        "/ads": 1,
        "/about": 2,
        "/contact": 3,
    };

    const [openLogin, setOpenLogin] = useState(false);
    const [openRegister, setOpenRegister] = useState(false);

    const handleLoginOpen = () => {
        setOpenLogin(true);
        setOpenRegister(false);
    };

    const handleRegisterOpen = () => {
        setOpenRegister(true);
        setOpenLogin(false);
    };

    const handleClose = () => {
        setOpenLogin(false);
        setOpenRegister(false);
    };

    // Helper function to convert the current path to a tab index
    const getCurrentTabValue = () => {
        if (location.pathname.match("/ads/") && location.pathname.match("/ads/").length > 0) {
            return 1;
        }
        return routeToTabIndex[location.pathname] || 0;
    };

    const [value, setValue] = useState(getCurrentTabValue());

    useEffect(() => {
        setLoading(true);
        // Update the tab value when the location changes
        setValue(getCurrentTabValue());
        setLoading(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, setLoading]); // Re-run this effect when the location changes

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const userRef = useRef();

    useEffect(() => {
        const fetchUser = async () => {
            setLoading(true);
            const userData = await getAuthenticatedUser(jwt);
            if (userData !== null) {
                userRef.current = userData;
            }
            setLoading(false);
        };
        if (jwt) {
            fetchUser();
        }
    }, [jwt, setLoading]);

    return (
        <AppBar position="fixed" open={open}
            sx={{
                backgroundColor: "white",
                boxShadow: "none",
                borderBottom: "1px solid rgba(0, 0, 0, 0.2)"
            }}
        >
            <p className="flex h-4 bg-primary" />
            <Toolbar sx={{ color: "black", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Box key={0} display='flex' flexDirection='row' alignItems='center'>
                    {isMatch && jwt && <PageDrawer setLoading={setLoading} />}
                    <Link to="/" sx={{ marginLeft: "3px" }}>
                        <img
                            className={isMatch ? "h-8 w-8 mr-0" : "h-10 w-10 mr-2"}
                            src={process.env.PUBLIC_URL + "/logo512.png"}
                            alt="logo-SendNous"
                        />
                    </Link>
                    <Link to="/">
                        <img
                            className={isMatch ? "h-8 w-32 mr-2" : "h-8 w-full mr-2"}
                            src={process.env.PUBLIC_URL + "/logo_name.png"}
                            alt="logo-SendNous-name"
                        />
                    </Link>
                    {/* <Typography variant="h5" sx={{ color: 'var(--primary)' }} noWrap component="div">
                        SendNous
                    </Typography> */}
                </Box>
                {isMatch ?
                    [jwt ?
                        userRef.current ?
                            <MenuUser setLoading={setLoading} key={1} user={userRef.current} />
                            :
                            <Stack direction='row' spacing={1} sx={{ ml: 'auto', mr: 1, alignItems: 'center' }}>
                                <Notifications setLoading={setLoading} />
                                <IconButton>
                                    <Avatar
                                        sx={{
                                            bgcolor: 'var(--primary)',
                                            height: isMatch ? '28px' : '40px', width: isMatch ? '28px' : '40px',
                                        }}
                                    >
                                    </Avatar>
                                </IconButton>
                            </Stack>
                        :
                        <PageDrawer setLoading={setLoading} key={2} />
                    ]
                    :
                    [
                        <Tabs key={3} textColor="inherit" indicatorColor="primary"
                            value={value}
                            onChange={handleChange}
                            sx={{ marginLeft: "auto" }}
                        >
                            {pages.map((page, index) => (
                                <Tab key={index} label={page.name} LinkComponent="a" href={page.href} />
                            ))}
                        </Tabs>,
                        jwt ?
                            (
                                userRef.current ?
                                    <MenuUser setLoading={setLoading} key={4} user={userRef.current} />
                                    :
                                    <Stack direction='row' spacing={1} sx={{ ml: 'auto', mr: 1, alignItems: 'center' }}>
                                        <Notifications setLoading={setLoading} />
                                        <IconButton>
                                            <Avatar
                                                sx={{
                                                    bgcolor: 'var(--primary)',
                                                    height: isMatch ? '28px' : '40px', width: isMatch ? '28px' : '40px',
                                                }}
                                            >
                                            </Avatar>
                                        </IconButton>
                                    </Stack>
                            )
                            :
                            (<div key={5} className="ml-auto">
                                <Button variant="contained"
                                    onClick={handleLoginOpen}
                                >
                                    Se Connecter
                                </Button>
                                <Button variant="contained" sx={{ marginLeft: "10px" }}
                                    onClick={handleRegisterOpen}
                                >
                                    S'inscrire
                                </Button>
                            </div>
                            )
                    ]}

            </Toolbar>
            <Authentication setLoading={setLoading} openLogin={openLogin} openRegister={openRegister} handleLoginOpen={handleLoginOpen}
                handleRegisterOpen={handleRegisterOpen} handleClose={handleClose} />
        </AppBar >
    )
}

export default NavBar
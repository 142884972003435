import { Box, CssBaseline, Grid, Paper, Typography, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MainDrawer, DrawerHeader } from "../../public_components/Layouts/Drawer";
import NavBar from "../../public_components/Layouts/NavBar";
import { status_array } from "../../../data/adData";
import { LocalOffer, People, Storefront, VolumeDown } from "@mui/icons-material";
import { getRelays } from "../../../services/relayService";
import { getUsers } from "../../../services/userService";
import { getOffers } from "../../../services/offerService";
import { getAds } from "../../../services/adService";
import { Bar, Pie, } from "react-chartjs-2";
import { Chart as ChartJS } from 'chart.js/auto'
import { useJwt } from "../../../utils/UserProvider";
import { useRole } from "../../../utils/UserProvider";

const Dashboard = ({setLoading}) => {
  const [open, setOpen] = useState(false);
  const { jwt } = useJwt();
  const { role } = useRole();
  const [info, setInfo] = useState({
    ads: [],
    offers: [],
    users: [],
    relays: [],
  })
  const updateInfo = (prop, value) => {
    setInfo(prev => ({
      ...prev,
      [prop]: value
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const adsData = await getAds(jwt);
      if (adsData !== null)
        updateInfo('ads', adsData);
      const offersData = await getOffers(jwt);
      if (offersData !== null)
        updateInfo('offers', offersData);
      if (role === 'ADMIN') {
        const usersData = await getUsers(jwt);
        if (usersData !== null)
          updateInfo('users', usersData);
      }
      const relaysData = await getRelays();
      if (relaysData !== null)
        updateInfo('relays', relaysData);
      setLoading(false);
    };

    if (jwt)
      fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt, setLoading])

  const typeLength = (type) => {
    return info.ads.filter((ad) => ad.type === type).length
  }

  const statusLength = (status) => {
    return info.ads.filter((ad) => ad.status === status).length
  }
  // const options = {
  //   maintainAspectRatio: false,
  //   scales: {
  //     x: {
  //       grid: {
  //         display: false
  //       }
  //     },
  //     y: {
  //       display: false,
  //       grid: {
  //         display: false
  //       }
  //     }
  //   },
  //   plugins: {
  //     legend: {
  //       display: false
  //     },
  //     title: {
  //       display: true,
  //       text: "Avg interest by month (days)",
  //       padding: {
  //         bottom: 30
  //       },
  //       weight: "bold",
  //       color: "#00325c",
  //       font: {
  //         size: 13
  //       },
  //       align: "start"
  //     },
  //     datalabels: {
  //       display: true,
  //       color: "white"
  //     }
  //   }
  // };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <NavBar setLoading={setLoading} open={open} setOpen={setOpen} />
      <MainDrawer setLoading={setLoading} open={open} setOpen={setOpen} />
      <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 3 }}>
        <DrawerHeader />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={role === 'ADMIN' ? 3 : 4}>
            <Paper elevation={3} style={{ padding: 20 }}>
              <Stack direction='row' spacing={10} display='flex' justifyContent='space-between' alignItems='center'>
                <Box>
                  <Typography variant="h4">{info.ads && info.ads.length}</Typography>
                  <Typography variant="h6" gutterBottom>
                    Annonces
                  </Typography>
                </Box>
                <VolumeDown color="primary" sx={{ fontSize: 50 }} />
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={role === 'ADMIN' ? 3 : 4}>
            <Paper elevation={3} style={{ padding: 20 }}>
              <Stack direction='row' spacing={10} display='flex' justifyContent='space-between' alignItems='center'>
                <Box>
                  <Typography variant="h4">{info.offers && info.offers.length}</Typography>
                  <Typography variant="h6" gutterBottom>
                    Offres
                  </Typography>
                </Box>
                <LocalOffer color="primary" sx={{ fontSize: 50 }} />
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={role === 'ADMIN' ? 3 : 4}>
            <Paper elevation={3} style={{ padding: 20 }}>
              <Stack direction='row' spacing={10} display='flex' justifyContent='space-between' alignItems='center'>
                <Box>
                  <Typography variant="h4">{info.relays && info.relays.length}</Typography>
                  <Typography variant="h6" gutterBottom>
                    Relais
                  </Typography>
                </Box>
                <Storefront color="primary" sx={{ fontSize: 50 }} />
              </Stack>
            </Paper>
          </Grid>
          {role === 'ADMIN' &&
            <Grid item xs={12} sm={6} md={3}>
              <Paper elevation={3} style={{ padding: 20 }}>
                <Stack direction='row' spacing={10} display='flex' justifyContent='space-between' alignItems='center'>
                  <Box>
                    <Typography variant="h4">{info.users && info.users.length}</Typography>
                    <Typography variant="h6" gutterBottom>
                      Utilisateurs
                    </Typography>
                  </Box>
                  <People color="primary" sx={{ fontSize: 50 }} />
                </Stack>
              </Paper>
            </Grid>
          }
          <Grid item xs={12} md={6}>
            <Paper elevation={3} style={{ minHeight: 370, padding: 20 }}>
              <Typography variant="h6" gutterBottom>
                Annonces par Type
              </Typography>
              {/* <div style={{ textAlign: 'center' }}>
                <CircleChart percentage={30} color="#FF6384" />
              </div> */}
              <Box sx={{ maxHeight: 300 }}>
                <Pie data={{
                  labels: ['Vente', 'Demande'],

                  datasets: [
                    {
                      label: "Nombre d'annonces",
                      data: [typeLength('SALE'), typeLength('REQUEST')],
                      backgroundColor: ['#FF6384', '#36A2EB'],
                      hoverBackgroundColor: ['#FF6384', '#36A2EB'],
                    },
                  ],
                }}
                />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} style={{ minHeight: 370, padding: 20 }}>
              <Typography variant="h6" gutterBottom>
                Annonces par Status
              </Typography>
              <Box sx={{ maxHeight: 300, width: '100%' }}>
                <Bar data={{
                  labels: status_array.map((status) => status.label),
                  datasets: [
                    {
                      label: "Nombre d'annonces",
                      data: status_array.map((status) => statusLength(status.value)), // Example percentages
                      backgroundColor: ['#adb5bd', '#0fb2cc', '#0b89d7', '#fd7e74', '#0af1cf', '#fd7e14', '#198754', '#dc3545'],
                      hoverBackgroundColor: ['#adb5bd', '#0fb2cc', '#0b89d7', '#fd7e74', '#0af1cf', '#fd7e14', '#198754', '#dc3545'],
                    },
                  ],
                }}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Dashboard;

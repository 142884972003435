import { CssBaseline, Box } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import Footer from '../../public_components/Layouts/Footer';
import { getAuthenticatedUser } from '../../../services/userService';
import UserInfo from '../UserProfile/UserInfo';
import UserProfile from '../UserProfile/UserProfile';
import UserAccount from '../UserProfile/UserAccount';
import NavBar from '../../public_components/Layouts/NavBar';
import { MainDrawer, DrawerHeader } from '../../public_components/Layouts/Drawer';
import { useRole } from '../../../utils/UserProvider';
import { useJwt } from '../../../utils/UserProvider';

const UserPage = ({ setLoading }) => {
    const { jwt } = useJwt();
    const [open, setOpen] = useState(false);
    const { role } = useRole();

    const [editable, setEditable] = useState(false);
    const userRef = useRef();

    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        username: "",
        email: "",
        password: "",
    });
    const updateUserFields = (prop, value) => {
        setUser(prevUser => ({
            ...prevUser,
            [prop]: value,
        }));
    }

    useEffect(() => {
        const fetchUser = async () => {
            setLoading(true);
            const userData = await getAuthenticatedUser(jwt);
            if (userData !== null) {
                setUser(userData);
                userRef.current = userData;
            }
            setLoading(false);
        };
        if (jwt) {
            fetchUser();
        }
    }, [jwt, setLoading]);


    const handleReset = async () => {
        try {
            setLoading(true);
            const userData = await getAuthenticatedUser(jwt);
            setUser(userData);
            // console.log(user);
            setEditable(false);
        }
        catch (error) {
            console.error("Error fetching user", error);
        }
        finally {
            setLoading(false);
        }
        setLoading(false);
    };

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <NavBar setLoading={setLoading} open={open} setOpen={setOpen} />
            <MainDrawer setLoading={setLoading} open={open} setOpen={setOpen} />
            <Box sx={{ flexGrow: 1 }}>
                <Box component="main" sx={{ p: 3, mt: 3 }}>
                    <DrawerHeader />
                    {userRef.current && [
                        <UserInfo key="userInfo" user={user} editable={editable} setEditable={setEditable}
                            handleReset={handleReset} />
                        ,
                        <UserProfile setLoading={setLoading} key="userProfile" user={user} setUser={setUser}
                            updateUserFields={updateUserFields} editable={editable}
                            setEditable={setEditable} handleReset={handleReset} />
                        ,
                        <UserAccount setLoading={setLoading} key="userAccount" user={user} setUser={setUser} />
                    ]}
                </Box>
                {role === 'USER' && <Footer />}
            </Box>
        </Box>
    );
}

export default UserPage
import { FormatQuoteOutlined } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React from 'react'

const Testimonials = () => {
    return (
        <Box>
            <Box sx={{ mx: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h4" sx={{ mb: 5, textAlign: 'center' }}>
                    Ce que nos clients disent de nous
                </Typography>
            </Box>
            <Box sx={{ mx: 'auto', display: 'grid', gridTemplateColumns: { lg: '1fr 1fr' }, gap: { lg: 20, xs: 1 }, px: { md: 10 }, mb: 10 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', m: { xs: 3, lg: 0 } }}>
                    <Box sx={{ position: 'relative', textAlign: 'center' }}>
                        <FormatQuoteOutlined sx={{ position: 'absolute', top: 0, left: 0, width: 32, height: 32, color: 'action.active' }} />
                        <Typography variant='body1' sx={{ px: 5, py: 1, fontStyle: 'italic' }}>
                            Super... Mon frère a reçu tous mes colis intacts en un temps record... C'est trop fort. Merci SendNous.
                        </Typography>
                        <FormatQuoteOutlined sx={{ position: 'absolute', bottom: 0, right: 0, width: 32, height: 32, color: 'action.active' }} />
                    </Box>
                    <Box sx={{ width: 48, height: 4, my: 2, backgroundColor: 'primary.main', borderRadius: 1 }} />
                    <Typography>Atangana Loïc</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', m: { xs: 3, lg: 0 } }}>
                    <Box sx={{ position: 'relative', textAlign: 'center' }}>
                        <FormatQuoteOutlined sx={{ position: 'absolute', top: 0, left: 0, width: 32, height: 32, color: 'action.active' }} />
                        <Typography variant='body1' sx={{ px: 5, py: 1, fontStyle: 'italic' }}>
                            Service de qualité, l'application fonctionne bien et les personnes qui assurent le relai ont été très serviables... Je recommande vivement !!!
                        </Typography>
                        <FormatQuoteOutlined sx={{ position: 'absolute', bottom: 0, right: 0, width: 32, height: 32, color: 'action.active' }} />
                    </Box>
                    <Box sx={{ width: 48, height: 4, my: 2, backgroundColor: 'primary.main', borderRadius: 1 }} />
                    <Typography>Iya Idrissa Mamat</Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default Testimonials
import ajax from "./ajax";

export const createUser = async (request, jwt) => {
  return ajax(`/admin/users/create`, "POST", jwt, request);
};

export const getUsers = async (jwt) => {
  return ajax(`/admin/users`, "GET", jwt);
};

export const searchUsers = async (request, jwt) => {
  const requestBody = {
    role: request.role,
  };
  return ajax(`/admin/users`, "POST", jwt, requestBody);
};

export const getAuthenticatedUser = async (jwt) => {
  return ajax(`/user/profile`, "GET", jwt);
};

export const getUserById = async (userId, jwt) => {
  return ajax(`/admin/users/${userId}`, "GET", jwt);
};

export const verifyUserPasswordById = async (userId, request, jwt) => {
  return ajax(`/user/users/verify-password/${userId}`, "POST", jwt, request);
};

export const updateUser = async (request, jwt) => {
  return ajax(`/user/users/${request.id}`, "PUT", jwt, request);
};

export const updateUserCredentials = async (request, jwt) => {
  return ajax(`/user/users/update-credentials/${request.id}`, "PUT", jwt, request);
};

export const forgotPassword = async (request) => {
  return ajax(`/api/password/forgot`, "POST", null, request);
};

export const updateUserRole = async (request, jwt) => {
  const requestBody = {
    role: request.role,
  };
  return ajax(`/admin/users/${request.id}`, "PUT", jwt, requestBody);
};


export const deleteUser = async (userId, jwt) => {
  return ajax(`/user/users/${userId}`, "DELETE", jwt);
};
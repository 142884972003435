import React from 'react';
import './Alert.css';
import { CheckCircle, ErrorOutline, InfoOutlined, WarningAmber } from '@mui/icons-material';

const Alert = ({ message, type, onClose }) => {
    const iconType = {
        success: <CheckCircle />,
        error: <ErrorOutline />,
        warning: <WarningAmber />,
        info: <InfoOutlined />
    };
    return (
        <div className={`alert alert-${type}`} style={{zIndex: 1500}}>
            {iconType[type]} {message}
            <button className="close-button" onClick={onClose}>×</button>
        </div>
    );
};

export default Alert;

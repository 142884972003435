import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle,
    FormControl, Grid, InputLabel, MenuItem, Select, TextField
} from '@mui/material';
import { MobileTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import React, { useEffect, useRef } from 'react'
import { getOpeningTimeById, updateOpeningTime } from '../../../services/openingTimeService';
import { days } from './days';
import { useJwt } from '../../../utils/UserProvider';
import showAlert from '../../../utils/ShowAlert';

const OpeningTimeEditDialog = ({ setLoading, opTimeId, openingTime, setOpeningTime,
    openUpdateOpTime, setOpenUpdateOpTime, setOpTimes }
) => {
    const { jwt } = useJwt();
    const opTimeRef = useRef();

    useEffect(() => {
        const fetchOpeningTime = async () => {
            setLoading(true);
            const opTimeData = await getOpeningTimeById(opTimeId, jwt);
            if (opTimeData !== null) {
                setOpeningTime({
                    ...opTimeData,
                    openingHour: dayjs(opTimeData.openingHour, 'HH:mm'),
                    closingHour: dayjs(opTimeData.closingHour, 'HH:mm')
                });
                opTimeRef.current = openingTime;
            }
            setLoading(false);
        };

        if (jwt) {
            fetchOpeningTime();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jwt, opTimeId, setOpeningTime, setLoading]);

    const updateOpTimeFields = (prop, value) => {
        setOpeningTime(prevOpTime => ({
            ...prevOpTime,
            [prop]: value
        }));
    }

    const handleCloseUpdateOp = () => {
        setOpeningTime({ ...opTimeRef.current });
        setOpenUpdateOpTime(false);
    };
    // Function to handle opening time form submission
    const handleSubmitEditOpTime = async () => {
        setLoading(true);
        const openingTimeUpdated = await updateOpeningTime({
            id: opTimeId,
            openingHour: openingTime.openingHour.format('HH:mm'),
            closingHour: openingTime.closingHour.format('HH:mm'),
            dayOfWeek: openingTime.dayOfWeek,
            relay: openingTime.relay
        }, jwt);
        if (openingTimeUpdated !== null) {
            setOpeningTime(openingTimeUpdated);
            setOpenUpdateOpTime(false);
            setOpTimes(prev => {
                const index = prev.findIndex((item) => item.id === openingTimeUpdated.id);
                prev = prev.map((op, i) => {
                    if (i === index)
                        return openingTimeUpdated;
                    else
                        return op;
                })
                return prev;
            })
            showAlert("Heure d'ouverture mise à jour !", 'info');
        }
        setLoading(false);
    };

    return (
        <Dialog
            open={openUpdateOpTime}
            onClose={handleCloseUpdateOp}
        >
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    handleSubmitEditOpTime();
                }}
            >
                <DialogTitle>Modifier l'horaire d'ouverture {opTimeId}</DialogTitle>
                <DialogContent>

                    <Grid container spacing={3} sx={{ padding: 3 }}>
                        <Grid item xs={12}>
                            <FormControl fullWidth required>
                                <InputLabel id="day-of-week-label">Sélectionnez le jour</InputLabel>
                                <Select
                                    required
                                    labelId="day-of-week-label"
                                    id="dayOfWeek"
                                    label="Sélectionnez le jour"
                                    value={openingTime.dayOfWeek}
                                    onChange={(e) => updateOpTimeFields("dayOfWeek", e.target.value)}
                                >
                                    {days.map((day) => [
                                        <MenuItem key={day.value} value={day.value}>
                                            {day.label}
                                        </MenuItem>
                                    ])}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <MobileTimePicker
                                slots={{
                                    textField: textFieldProps => <TextField {...textFieldProps} required />
                                }}
                                label="Heure d'ouverture"
                                ampm={false}
                                minTime={dayjs('00:00', 'HH:mm')}
                                maxTime={dayjs('23:59', 'HH:mm')}
                                value={openingTime.openingHour}
                                onChange={(newValue) => {
                                    updateOpTimeFields("openingHour", newValue);
                                    // Reset closing hour if it's earlier than the new opening hour
                                    if (openingTime.closingHour && newValue && dayjs(openingTime.closingHour).isBefore(newValue)) {
                                        updateOpTimeFields("closingHour", null);
                                    }
                                }}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MobileTimePicker
                                slots={{
                                    textField: textFieldProps => <TextField {...textFieldProps} required />
                                }}
                                label="Heure de fermeture"
                                ampm={false}
                                // Ensure the minTime is set to one minute after the openingHour, or another appropriate logic
                                minTime={openingTime.openingHour ? dayjs(openingTime.openingHour).add(1, 'minute') : dayjs('00:00', 'HH:mm')}
                                maxTime={dayjs('23:59', 'HH:mm')}
                                value={openingTime.closingHour}
                                onChange={(newValue) => updateOpTimeFields("closingHour", newValue)}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' type="submit">
                        Enregistrer
                    </Button>
                    <Button variant='contained' onClick={handleCloseUpdateOp}>
                        Annuler
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default OpeningTimeEditDialog
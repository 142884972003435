import { Sms } from '@mui/icons-material';
import React from 'react'
import { useNavigate } from 'react-router-dom';

const HomeAdCard = ({ ad }) => {
  const navigate = useNavigate();

  return (

    <div className='cursor-pointer flex flex-col items-center bg-white rounded-lg 
      shadow-lg overflow-hidden w-[15rem] h-[20rem] mx-3'
      onClick={() => navigate(`/ads/${ad.id}`)}
    >
      <div className="relative w-[15rem] h-[13rem]">
        <img src={ad.picture}
          className='object-cover object-top w-full h-full' alt='Ad luggage' />
        <div className="bg-primary rounded text-white absolute  start-0 top-0 m-4 py-1 px-3">
          {ad.title}
        </div>
        {/* <div className="bg-white font-semibold rounded-t text-primary absolute start-0 bottom-0 mx-4 pt-1 px-3">
          {ad.weight} KG - {ad.price} MAD
        </div> */}
        <div className="flex flex-row space-x-3 align-center bg-white font-semibold rounded-t text-primary absolute start-0 bottom-0 mx-2 py-1 px-2">
          <span>{ad.weight} KG</span>
          <span>{ad.price}MAD</span>
          <span>
            <span className='text-sm text-primary'>{ad.offersNumber}</span>
            <Sms color='primary' sx={{ height: 20 }} />
          </span>
        </div>
      </div>
      <div className="p-4 w-[15rem]">
        {ad && ad.type === 'SALE' && (
          <>
            <p className='mt-2 text-sm'>
              <span>Délai de dépôt: </span>
              <span className='font-semibold'>{ad.depositDeadline}</span>
            </p>
            <p className='mt-2 text-sm'>
              <span>Date de départ: </span>
              <span className='font-semibold'>{ad.departureDate}</span>
            </p>
            <p className='mt-2 text-sm'>
              <span>Date d'arrivée: </span>
              <span className='font-semibold'>{ad.arrivalDate}</span>
            </p>
          </>
        )}
        {ad && ad.type === 'REQUEST' && (
            <>
                <p className='mt-2 text-sm'>
                    <span>Voyage entre le: </span>
                    <span className='font-semibold'>{ad.minDate}</span>
                </p>
                <p className='mt-2 text-sm'>
                    <span>et le: </span>
                    <span className='font-semibold'>{ad.maxDate}</span>
                </p>
                <p className='mt-2 text-sm'>
                    <span>Nombre de bagage:  </span>
                    <span className='font-semibold'>{ad.luggageNumber}</span>
                </p>
            </>

        )}
        <p className='mt-2 text-sm font-semibold'>
          {ad.departureRelay.city} - {ad.arrivalRelay.city}
        </p>
      </div>
    </div>
  )
}

export default HomeAdCard
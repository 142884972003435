import { MenuItem, Select } from '@mui/material';
import React from 'react'

const SelectFilter = ({ item, applyValue, options }) => {
  const handleChange = (event) => {
    applyValue({ ...item, value: event.target.value });
  };
  return (
    <Select value={item.value || ''} onChange={handleChange} displayEmpty>
     {[
      <MenuItem key={0} value="">
        <em>Aucun</em>
      </MenuItem>
      ]}
      {options.map((option) => [
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ])}
    </Select>
  );
};

export default SelectFilter
import {
  Avatar, Button, Card, CardContent, CardHeader, Chip,
  CssBaseline, Divider, Box
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { deleteAd, getAdsByPublisher } from '../../../services/adService';
import { adTypes, statusColor, statusLabel, status_array, typeLabel } from '../../../data/adData';
import Footer from '../../public_components/Layouts/Footer';
import AdCreateDialog from './AdCreateDialog';
import { Add, Delete, Visibility } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import SelectFilter from '../SelectFilter/SelectFilter';
import { MainDrawer, DrawerHeader } from '../../public_components/Layouts/Drawer';
import NavBar from '../../public_components/Layouts/NavBar';
import { useRole } from '../../../utils/UserProvider';
import DeleteDialog from '../../public_components/Layouts/DeleteDialog';
import { useJwt } from '../../../utils/UserProvider';

const AdsPublished = ({ setLoading }) => {
  const { jwt } = useJwt();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { role } = useRole();

  const [ads, setAds] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const adIdRef = useRef();
  const [openDelete, setOpenDelete] = useState(false);
  const [openCreateAd, setOpenCreateAd] = useState(false);

  useEffect(() => {
    const fetchAds = async () => {
      setLoading(true);
      const adsData = await getAdsByPublisher(jwt);
      setAds(adsData);
      setLoading(false);
    };

    if (jwt) {
      fetchAds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoading])

  const handleClickCreateAd = () => {
    setOpenCreateAd(true);
  };

  const handleClickDeleteAd = (id) => {
    adIdRef.current = id;
    setOpenDelete(true);
  };


  const typeFilter = {
    label: '=',
    value: 'type', // Assuming 'type' is the field in your data
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.value) return null;
      return ({ value }) => value === filterItem.value;
    },
    InputComponent: SelectFilter,
    InputComponentProps: {
      options: adTypes,
    },
  };

  const statusFilter = {
    label: '=',
    value: 'status', // Assuming 'status' is the field in your data
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.value) return null;
      return ({ value }) => value === filterItem.value;
    },
    InputComponent: SelectFilter,
    InputComponentProps: {
      options: status_array,
    },
  };


  const columns = useMemo(() => [
    {
      field: 'actions',
      headerName: "Actions",
      type: 'actions',
      width: 250,
      renderCell: (params) =>
        <Box
          sx={{
            m: 1,
            position: 'relative',
            display: 'flex',
            gap: '5px'
          }}
        >
          <Button
            variant="outlined"
            color='info'
            size='small'
            startIcon={<Visibility />}
            onClick={() => {
              navigate(`/user/ads/${params.id}`);
            }}
          >
            Détails
          </Button>
          {(params.row.status === 'PENDING_VALIDATION' || params.row.status === 'CANCELLED') &&
            <Button
              variant="outlined"
              color='error'
              size='small'
              startIcon={<Delete />}
              onClick={(e) => {
                e.preventDefault();
                handleClickDeleteAd(params.id);
              }}
            >
              Supprimer
            </Button>
          }
        </Box>
    },
    {
      field: 'id',
      headerName: "Id",
      hideable: false,
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 130,
      filterOperators: [typeFilter],
      renderCell: (params) => (
        <Chip
          avatar={<Avatar src={params.row.picture} />}
          label={
            typeLabel(params.row.type)
          }
          variant="outlined"
        />
      ),
      valueFormatter: ({ value }) => typeLabel(value),
    },
    {
      field: 'status',
      headerName: "Statut",
      width: 170,
      filterOperators: [statusFilter],
      renderCell: (params) => (
        <Chip
          label={
            statusLabel(params.row.status)
          }
          variant="outlined"
          color={statusColor(params.row.status)}
        />
      ),
      valueFormatter: ({ value }) => statusLabel(value),
    },
    {
      field: 'title',
      headerName: "Titre",
      width: 200,
    },
    {
      field: 'creationDate',
      headerName: "Créée le",
      valueFormatter: ({ value }) => value ? dayjs(value).format('YYYY-MM-DD') : '',
    },
    {
      field: 'publicationDate',
      headerName: "Publiée le",
      valueFormatter: ({ value }) => value ? dayjs(value).format('YYYY-MM-DD HH:MM') : '',
      width: 150,
    },
    {
      field: 'offersNumber',
      headerName: "Offres",
      width: 70,
    },
    {
      field: 'departureRelay',
      headerName: 'Départ',
      renderCell: (params) => params.row.departureRelay.city,
      valueFormatter: ({ value }) => `${value.city} - ${value.country}`,
      width: 150,
    },
    {
      field: 'arrivalRelay',
      headerName: 'Arrivée',
      renderCell: (params) => params.row.arrivalRelay.city,
      valueFormatter: ({ value }) => `${value.city} - ${value.country}`,
      width: 150,
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], []);

  const getRowSpacing = useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 0 : 5,
    };
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <NavBar setLoading={setLoading} open={open} setOpen={setOpen} />
      <MainDrawer setLoading={setLoading} open={open} setOpen={setOpen} />
      <Box sx={{ flexGrow: 1, width: '100%' }}>
        <Box component="main" sx={{ p: 3, mt: 3 }}>
          <DrawerHeader />
          <Card
            sx={{
              borderRadius: 3,
              px: 2,
              py: 2,
              marginBottom: 2,
              width: '100%',
            }}
          >
            <CardHeader title="Gérer Mes Annonces"
              sx={{
                marginBottom: 2,
              }}
            />
            <Divider />
            <CardContent>
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={() => {
                  // table.setCreatingRow(true); //simplest way to open the create row modal with no default values
                  handleClickCreateAd();
                }}
                sx={{ textAlign: 'center', my: 3, }}
              >
                Créer une annonce
              </Button>
              <DataGrid
                columns={columns}
                autoHeight
                rows={ads}
                initialState={{
                  ...ads.initialState,
                  pagination: { paginationModel: { pageSize: pageSize } },
                  sorting: {
                    sortModel: [{ field: 'id', sort: 'desc' }],
                  },
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterExcludeHiddenColumns: true,
                    },
                  },
                  columns: {
                    columnVisibilityModel: {
                      id: false,
                    },
                  },
                }}
                pageSize={pageSize}
                pageSizeOptions={[5, 10, 25]}
                rowHeight={90}
                getRowSpacing={getRowSpacing}
                rowSpacingType="border"
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                sx={{
                  // '--DataGrid-overlayHeight': '300px',
                  border: 'none',
                  width: '100%',
                  mb: 5,
                  '& .MuiDataGrid-row': { borderTopColor: 'white', borderTopStyle: 'solid' }
                }}
              />
            </CardContent>
          </Card >
          <AdCreateDialog setLoading={setLoading} openCreateAd={openCreateAd} setOpenCreateAd={setOpenCreateAd} setAds={setAds} />
          <DeleteDialog setLoading={setLoading} open={openDelete} setOpen={setOpenDelete} id={adIdRef.current} deleteMethod={deleteAd} setData={setAds} />
        </Box>
        {role !== 'ADMIN' && role !== 'COORDINATOR' && <Footer />}
      </Box>
    </Box>
  );
}

export default AdsPublished
export const user = {
  id: "1",
  firstName: "haoua amamatou",
  lastName: "Mairou",
  phone: "+212 657949363",
  username: "mhao",
  email: "mhao@hotmail.com",
  role: "ADMIN",
};

export const role_array = [
  { value: "USER", label: "Utilisateur" },
  { value: "COORDINATOR", label: "Coordinateur" },
  { value: "ADMIN", label: "Administrateur" },
];
export const role = ["USER", "COORDINATOR", "ADMIN"];

export function roleLabel(role) {
  const roleObject = role_array.find(
    (r) => r.value === role
  );
  const role_label = roleObject ? roleObject.label : '';
  return role_label;
}

// export const fakeData = [
//   {
//     id: "1",
//     firstName: "Kelvin",
//     lastName: "Langosh",
//     phone: "+212 657949363",
//     username: "Jerod14",
//     email: "Jerod14@hotmail.com",
//     role: "ADMIN",
//   },
//   {
//     id: "2",
//     firstName: "Molly",
//     lastName: "Purdy",
//     phone: "+212 657949363",
//     username: "Dach79",
//     email: "Hugh.Dach79@hotmail.com",
//     role: "COORDINATOR",
//   },
//   {
//     id: "3",
//     firstName: "Henry",
//     lastName: "Lynch",
//     phone: "+212 657949363",
//     username: "Macejkovic",
//     email: "Camden.Macejkovic@yahoo.com",
//     role: "USER",
//   },
//   {
//     id: "4",
//     firstName: "Glenda",
//     lastName: "Douglas",
//     phone: "+212 657949363",
//     username: "Eric0",
//     email: "Eric0@yahoo.com",
//     role: "COORDINATOR",
//   },
//   {
//     id: "5",
//     firstName: "Leone",
//     lastName: "Williamson",
//     phone: "+212 657949363",
//     username: "Ericka_Mueller52",
//     email: "Ericka_Mueller52@yahoo.com",
//     role: "USER",
//   },
//   {
//     id: "6",
//     firstName: "Mckenna",
//     lastName: "Friesen",
//     phone: "+212 657949363",
//     username: "Veda_Feeney",
//     email: "Veda_Feeney@yahoo.com",
//     role: "USER",
//   },
//   {
//     id: "7",
//     firstName: "Wyman",
//     lastName: "Jast",
//     phone: "+212 657949363",
//     username: "Melvin",
//     email: "Melvin.Pacocha@yahoo.com",
//     role: "USER",
//   },
//   {
//     id: "8",
//     firstName: "Janick",
//     lastName: "Willms",
//     phone: "+212 657949363",
//     username: "Delfina12",
//     email: "Delfina12@gmail.com",
//     role: "USER",
//   },
// ];

